import { domReady } from 'common/dom';
import { Breakpoint } from 'common/responsive';

import scrollToElementTop from 'website-rendering/helpers/scollToElementTop';

/**
 * This function fetches the appropriate elements and then calls `toggleItem` based on
 * the element to which the function is attached to. This could be done directly,
 * but then it's impossible to remove the eventListener. We need to be able to
 * remove the eventListener in case the element is re-rendered in React.
 */
function handleItemClick(this: HTMLElement, e: MouseEvent) {
    e.preventDefault();

    const item = this.closest<HTMLDetailsElement>(
        '.jw-element-accordion__item'
    );
    const accordion = this.closest<HTMLElement>('.jw-element-accordion');

    if (!item || !accordion) {
        return;
    }

    toggleItem(accordion, item);
}

export function initializeAccordion(accordion: HTMLElement | null): void {
    if (!accordion) {
        return;
    }
    const items = accordion.querySelectorAll<HTMLDetailsElement>(
        '.jw-element-accordion__item'
    );

    // By setting this class the accordion is only usable on mobile.
    // It's closed on mobile by default and disabled desktop on desktop by CSS.
    const closeMobile =
        accordion.classList.contains('jw-element-accordion--mobile-only') &&
        window.matchMedia(`(max-width: ${Breakpoint.SM}px)`).matches;

    items.forEach((item) => {
        const heading = item.querySelector(
            '.jw-element-accordion__heading'
        ) as HTMLElement;

        // Clean up event listeners in case it's reinitialized
        heading.removeEventListener('click', handleItemClick);
        heading.addEventListener('click', handleItemClick);

        if (closeMobile) {
            item.open = false;
        }
    });
}

function toggleItem(accordion: HTMLElement, item: HTMLDetailsElement) {
    const content = item.querySelector(
        '.jw-element-accordion__content'
    ) as HTMLElement;

    const openStartClass = 'jw-element-accordion__content--open-start';
    const openActiveClass = 'jw-element-accordion__content--open-active';
    const closeStartClass = 'jw-element-accordion__content--close-start';
    const closeActiveClass = 'jw-element-accordion__content--close-active';
    const isOpen = item.open;

    if (!isOpen) {
        content.classList.add(openStartClass);
        item.open = true;
        content.style.setProperty(
            '--computedHeight',
            `${content.scrollHeight}px`
        );
        content.classList.add(openActiveClass);

        // Close other items if always open is disabled
        if (accordion.classList.contains('jw-element-accordion--single-open')) {
            closeOtherItems(accordion, item);
        }
    } else {
        content.style.setProperty(
            '--computedHeight',
            `${content.scrollHeight}px`
        );
        content.classList.add(closeStartClass);
        content.offsetHeight; // force reflow
        content.classList.add(closeActiveClass);
    }

    content.addEventListener(
        'transitionend',
        () => {
            content.classList.remove(
                openStartClass,
                openActiveClass,
                closeStartClass,
                closeActiveClass
            );

            content.style.setProperty('--computedHeight', '');

            if (isOpen) {
                item.open = false;
            } else {
                // Scroll opened item into view if the top is not currently in view
                if (content.getBoundingClientRect().top < 0) {
                    scrollToElementTop(item);
                }
            }
        },
        { once: true }
    );
}

function closeOtherItems(
    accordion: HTMLElement,
    currentItem: HTMLDetailsElement
) {
    const openItems = accordion.querySelectorAll<HTMLDetailsElement>('[open]');
    openItems.forEach((item) => {
        if (item !== currentItem) {
            toggleItem(accordion, item);
        }
    });
}

void domReady().then(() => {
    const accordions = document.querySelectorAll<HTMLElement>(
        '.jw-element-accordion'
    );
    accordions.forEach((accordion) => initializeAccordion(accordion));
});

import jwGlobal from 'common/config';
import loadLanguage from './internal/loadLanguage';

const {
    locale,
    localeLanguage,
    localeRegion,
    timezone,
    sprintf,
    gettext: w,
    ngettext: wn,
    pgettext: wx,
    npgettext: wnx,
    formatNumber,
    parseNumber,
    parseNumberAsString,
    formatCurrency,
    formatCurrencyNumber,
    formatMoney,
    getCurrencySymbol,
    formatPercentage,
    fetchCountryNames,
    isCountrySubdivisionRequired,
    fetchCountrySubdivisions,
    formatAddress,
    __,
} = loadLanguage(
    'website-rendering',
    jwGlobal.websiteRendering.locale,
    jwGlobal.websiteRendering.timezone,
    jwGlobal.website.webshop.currency
);

export {
    locale,
    localeLanguage,
    localeRegion,
    timezone,
    sprintf,
    w,
    wn,
    wx,
    wnx,
    formatNumber,
    parseNumber,
    parseNumberAsString,
    formatCurrency,
    formatCurrencyNumber,
    formatMoney,
    getCurrencySymbol,
    formatPercentage,
    fetchCountryNames,
    isCountrySubdivisionRequired,
    fetchCountrySubdivisions,
    formatAddress,
    __,
};

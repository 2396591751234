import $ from 'jquery';

/**
 * Replaces the given element with the given HTML and returns the new element.
 *
 * @param {HTMLElement} element
 * @param {string} html
 * @returns {HTMLElement}
 */
export default function replaceWith(element, html) {
    const $newElement = $(html);
    $(element).replaceWith($newElement);
    return $newElement[0];
}

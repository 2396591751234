import { w } from 'common/i18n/website-rendering';
import { getWhatsappUrl } from 'common/whatsapp';

import { getSocialNetworkTitle, getSocialNetworkUrl } from './socialNetworks';

function shouldRenderItem(item, inEditor) {
    if (!item?.active) {
        return false;
    }

    if (!inEditor) {
        return item.value !== null && item.value !== '';
    }

    return item.value !== null;
}

function renderItem({
    href,
    icon,
    label,
    badge = false,
    disable = false,
    newTab = false,
}) {
    let renderIcon = `<span class="website-rendering-icon-${icon}"></span>`;
    if (badge) {
        renderIcon = `
            <span class="jw-icon-badge-wrapper">
                ${renderIcon}
                <span class="jw-icon-badge">${badge}</span>
            </span>`;
    }
    return `
        <a
            ${disable ? '' : `href="${href}"`}
            class="mobile-bar__item jw-allow"
            ${newTab ? 'target="_blank"' : ''}
        >
            <span class="mobile-bar__item-icon">${renderIcon}</span>
            <span class="mobile-bar__item-label">${label}</span>
        </a>
    `;
}

function getRenderProps(type, state, inEditor) {
    switch (type) {
        case 'email':
            return {
                href: `mailto:${state.email.value}`,
                icon: 'mail',
                label: w('Email'),
                disable: inEditor,
            };

        case 'phone':
            return {
                href: `tel:${state.phone.value}`,
                icon: 'phone',
                label: w('Phone'),
                disable: inEditor,
            };

        case 'location':
            return {
                href: `http://maps.apple.com/?daddr=${state.location.value}`,
                icon: 'location',
                label: w('Map'),
                newTab: inEditor,
            };

        case 'social':
            return {
                href: getSocialNetworkUrl(
                    state.social.network,
                    state.social.value
                ),
                icon: state.social.network,
                label: getSocialNetworkTitle(state.social.network),
                newTab: inEditor,
            };

        case 'whatsapp':
            return {
                href: getWhatsappUrl(state.whatsapp.value),
                icon: 'whatsapp',
                label: w('WhatsApp'),
                disable: inEditor,
            };
    }
}

function renderItems(state, inEditor) {
    const items = ['email', 'phone', 'location', 'social', 'whatsapp'].filter(
        (type) => shouldRenderItem(state[type], inEditor)
    );

    // Remove 'Location' item if there are too many items
    if (items.length > 5) {
        items.splice(items.indexOf('location'), 1);
    }

    return items
        .map((type) => renderItem(getRenderProps(type, state, inEditor)))
        .join('');
}

function render(state, inEditor) {
    return `
        <div class="mobile-bar mobile-bar--${state.theme}">
            ${renderItems(state, inEditor)}
        </div>
    `;
}

function getContainer() {
    let container = document.querySelector('.jw-mobile-bar-container');

    if (container === null) {
        container = document.createElement('div');
        container.classList.add('jw-mobile-bar-container');
        document
            .querySelector('.jw-bottom-bar__container')
            .appendChild(container);
    }

    return container;
}

export default function renderMobileBar(state, inEditor = false) {
    // Render spacer if it doesn't exist
    let spacer = document.querySelector('.jw-website-spacer--mobile-bar');
    if (spacer === null) {
        spacer = document.createElement('div');
        spacer.classList.add(
            'jw-website-spacer',
            'jw-website-spacer--mobile-bar'
        );
        document.querySelector('.jw-bottom-bar__spacer').appendChild(spacer);
    }

    const container = getContainer();
    container.classList.toggle('hidden', !state.enabled);
    spacer.classList.toggle('hidden', !state.enabled);

    if (state.enabled) {
        container.innerHTML = render(state, inEditor);
    }
}

import Jed from 'jed';
import config from 'common/config';

export interface Translator {
    sprintf(format: string, ...args: unknown[]): string;
    gettext(key: string): string;
    ngettext(singular_key: string, plural_key: string, value: number): string;
    pgettext(domain: string, key: string): string;
    npgettext(domain: string, context: string, key: string): string;
}

export default function loadTranslator(
    domain: string,
    locale: string
): Translator {
    const { localization } = config;
    const translations = localization?.[`${domain}:${locale}`];

    if (translations === undefined) {
        console.error(
            `Localization for "${domain}" with locale "${locale}" is not available.`
        );
    }

    // our translator is basically a wrapper around Jed, a gettext library:
    // https://www.npmjs.com/package/jed
    const jed = new Jed(translations ?? {});

    return {
        sprintf: Jed.sprintf,
        gettext: jed.gettext.bind(jed),
        ngettext: jed.ngettext.bind(jed),
        pgettext: jed.pgettext.bind(jed),
        npgettext: jed.npgettext.bind(jed),
    };
}

import $ from 'jquery';
import textFit from 'textfit';
import 'superfish';
import { throttle } from 'lodash-es';

import config from 'common/config';
import { domReady } from 'common/dom';

const onMenuReloadListeners = [];
let reloadAll;

function isMenuCollapsed() {
    return $('body').hasClass('jw-menu-is-collapsed');
}

function setMenuCollapsed(collapsed) {
    $('body').toggleClass('jw-menu-is-collapsed', collapsed);
    $('.jw-mobile-toggle > .jw-icon-burger').toggleClass(
        'jw-icon-burger--cross',
        !collapsed
    );
}

domReady().then(() => {
    const $menu = $('#jw-menu');
    if ($menu.length === 0) {
        return;
    }

    let $collapseMenu = $('.jw-menu-collapse');
    if ($collapseMenu.length === 0) $collapseMenu = $menu;

    let mobileMenuParent;
    let $mobileHeaderText;

    let currentWindowWidth = $(window).width();

    // Do not run this module at all
    if (
        $menu.data('javascript') !== undefined &&
        $menu.data('javascript') === 0
    ) {
        return;
    }

    const $body = $('body');

    const forceMobileBelowWidth = 500;
    const forceDesktopAboveWidth = 800;

    const mobileState = 'jw-menu-is-mobile';
    const desktopState = 'jw-menu-is-desktop';

    // If mobile menu is openend, any click outside of the menu should close it
    // Note: this event handler is initialized here in order to prevent any
    // complex binding and unbinding depending on mobile menu state
    $(document).on(
        'click',
        'body.jw-menu-is-mobile:not(.jw-menu-is-collapsed)',
        (e) => {
            if (!mobileMenuParent?.contains(e.target)) {
                const menuPosition =
                    getComputedStyle(mobileMenuParent).position;
                if (menuPosition === 'absolute' || menuPosition === 'fixed') {
                    // only close menu if it is an overlay
                    setMenuCollapsed(true);
                    return false;
                }
            }
        }
    );

    // Get the state we have last set. A forced state passed via data attributes
    // does thus not count as a state; because it is not applied yet.
    function getState() {
        if ($menu.data('state')) {
            return $menu.data('state');
        }

        return false;
    }

    function setState(state) {
        $body.toggleClass(mobileState, state === 'mobile');
        $body.toggleClass(desktopState, state === 'desktop');
        $menu.data('state', state);
    }

    // During resizing we keep track of the thresholds below and above which we already know in
    // what state to render the menu. We can initialize these variables with the thresholds above
    // and below to always render a mobile menu.
    let mobileRenderingBelow = forceMobileBelowWidth;
    let desktopRenderingAbove = forceDesktopAboveWidth;

    function testPreferredState() {
        const clientWidth = document.documentElement.clientWidth;

        if ($menu.hasClass('jw-menu-vertical')) {
            // A vertical menu can not shrink, thus use the threshold to decide whether or not to
            // go mobile.
            return clientWidth < $body.data('template-threshold')
                ? 'mobile'
                : 'desktop';
        }
        if ($menu.data('force-state')) {
            return $menu.data('force-state');
        }
        if (clientWidth < mobileRenderingBelow) {
            return 'mobile';
        }
        if (clientWidth > desktopRenderingAbove) {
            return 'desktop';
        }

        const currentState = getState();
        let menuWasCollapsed = false;

        // Use desktop rendering to make sure menu is horizontally ordered
        setState('desktop');

        if (isMenuCollapsed()) {
            menuWasCollapsed = true;
            setMenuCollapsed(false);
        }

        // Force synchronous reflow.
        // Waiting for asynchronous reflow potentially introduces race conditions in the menu measurement.
        $menu[0].offsetHeight;

        // Measure this before we change state
        const menuHasMoved = testIsFilledMenuMoved();

        // Restore state
        setState(currentState);
        if (menuWasCollapsed) {
            setMenuCollapsed(true);
        }

        // Decide on state
        const preferredState = menuHasMoved ? 'mobile' : 'desktop';

        // Remember for next time
        if (preferredState === 'mobile') {
            mobileRenderingBelow = Math.max(mobileRenderingBelow, clientWidth);
        } else if (preferredState === 'desktop') {
            desktopRenderingAbove = Math.min(
                desktopRenderingAbove,
                clientWidth
            );
        }

        return preferredState;
    }

    function destroyDesktopMenu() {
        if ($menu.data('superfish') === 0) {
            return;
        }
        $menu.superfish('destroy');
    }

    function hasMenuMultipleLines() {
        const $items = $menu.find('> .jw-menu-item:visible');

        if ($items.length === 0) {
            return false;
        }

        // IE has some nasty flexbox bugs. Fall back to multiline styling sooner in IE:
        if (/Trident/.test(navigator.userAgent)) {
            const itemsContainer = $items.parents('.jw-menu')[0];
            const menu = itemsContainer.parentNode;
            const menuContainer = menu.parentNode;

            if (menu.offsetWidth / menuContainer.offsetWidth > 0.8) {
                return true;
            }
        }

        const { bottom: firstBottom } = $items
            .first()[0]
            .getBoundingClientRect();
        const { top: lastTop } = $items.last()[0].getBoundingClientRect();
        return Math.floor(firstBottom) <= Math.ceil(lastTop);
    }

    /*
     * Test if the menu will be moved when all menu items are to be shown.
     *
     * Note that the menu should be in desktop state to check this!
     *
     * We test this by actually only showing the first menu item, and then by placing all others
     * back.
     */
    function testIsFilledMenuMoved() {
        // Hide all menu items.
        const $items = $menu.find('> .jw-menu-item');
        if ($items.length === 0) {
            return false;
        }

        $items.addClass('hidden');
        $items.first().removeClass('hidden');

        // Let reflow and take notes of position
        $items.first().offset();
        const firstPosition = $items.first().offset().top;

        // Place everything back and take new positions
        $items.removeClass('hidden');
        $items.last().offset();
        const newFirstPosition = $items.first().offset().top;

        // Require some serious movement before deciding we have moved. See #2120
        return Math.abs(newFirstPosition - firstPosition) > 5;
    }

    function enableDesktopMenu() {
        reloadDesktopMenu();

        // Initialize superfish
        if ($menu.data('superfish') === 0) {
            return;
        }

        setMenuCollapsed(false);
        $menu.show().superfish({
            hoverClass: 'jw-menu-is-hover',
            speed: 150,
            speedOut: 150,
            onHandleTouch() {
                // work around Superfish bug in recent versions of iOS
                // https://github.com/joeldbirch/superfish/issues/182#issuecomment-549307916
                if (
                    navigator.platform === 'MacIntel' &&
                    navigator.maxTouchPoints > 1
                ) {
                    return false;
                }
            },
            onBeforeShow() {
                // In IE1 onBeforeShow is sometimes triggered with a non existing list
                if ($(this).length === 0) {
                    return;
                }

                const windowWidth = $(window).width();
                const parentWidth = $(this).parent().width();
                const menuOffset = $(this).parent().offset().left;
                const menuWidth = $(this).width();

                const isFirstDropDown = $(this)
                    .parent()
                    .parent()
                    .is('#jw-menu');

                // Shift the first menu to the left when it overflows at the right edge of the
                // screen.
                const overflow =
                    isFirstDropDown && menuOffset + menuWidth - windowWidth;
                if (overflow > 0) {
                    const gapBetweenEdgeOfScreen = 5;
                    $(this).css(
                        'transform',
                        `translate(${-(
                            overflow + gapBetweenEdgeOfScreen
                        )}px, 0)`
                    );
                }

                // menu overflows at right side of screen
                if (menuOffset + parentWidth + menuWidth > windowWidth) {
                    $(this)
                        .closest('.jw-menu-item')
                        .addClass('jw-menu-is-overflowing')
                        .find('.jw-menu-item')
                        .addClass('jw-menu-is-overflowing');
                }

                // menu overflows at left side of screen
                if (menuOffset < $(this).width()) {
                    $(this)
                        .closest('.jw-menu-item')
                        .removeClass('jw-menu-is-overflowing')
                        .find('.jw-menu-item')
                        .removeClass('jw-menu-is-overflowing');
                }
            },
        });
    }

    function reloadDesktopMenu() {
        if (getState() !== 'desktop') {
            return;
        }

        $('body')
            .toggleClass(
                'jw-is-menu-vertical-overflow',
                testIsFilledMenuMoved()
            )
            .removeClass('jw-is-menu-multiline')
            .toggleClass('jw-is-menu-multiline', hasMenuMultipleLines());

        // align popovers differently if popover toggle is on the left of the
        // screen
        $('#jw-menu .jw-popover').each(function () {
            const $popover = $(this).parents('.jw-menu-item');
            const isLeft =
                $popover.offset().left -
                    $('.js-topbar-content-container').offset().left <
                $('.js-topbar-content-container').width() / 2;
            $popover.toggleClass('jw-menu-item--left', isLeft);
        });
    }

    function destroyMobileMenu() {
        setMenuCollapsed(false);
        $menu.off('click');
    }

    function enableMobileMenu() {
        reloadMobileMenu();

        // todo rename this as .jw-menu-copy is now only used to see if the template has this type
        // of mobile menu. It's not used any more to "copy" the menu.
        const copyMenu = document.querySelector('.jw-menu-copy .jw-menu');
        const mobileMenuTemplate = document.getElementById(
            'jw-mobile-menu-template'
        ).innerHTML;

        if (!mobileMenuParent && copyMenu) {
            mobileMenuParent = document.createElement('nav');
            mobileMenuParent.classList.add(
                'menu',
                'jw-menu-clone',
                'jw-menu-collapse'
            );
            mobileMenuParent.innerHTML = mobileMenuTemplate;

            const mobileMenuAfter = document.querySelector(
                '.jw-mobile-menu-after'
            );
            if (mobileMenuAfter) {
                mobileMenuAfter.insertAdjacentElement(
                    'afterEnd',
                    mobileMenuParent
                );
            } else {
                const jwMobileMenu = document.querySelector('.jw-mobile-menu');
                jwMobileMenu.insertAdjacentElement(
                    'afterEnd',
                    mobileMenuParent
                );
            }
        }

        setMenuCollapsed(true);
        const $currentMenu = $(mobileMenuParent) || $menu;

        $('.jw-mobile-toggle')
            .off('touchstart click')
            .on(
                'touchstart click',
                throttle(
                    () => {
                        // I assume the throttle() is here to allow for both touchstart
                        // and click, but I think we can do with just 'click' these days
                        // TODO remove 'touchstart', throttle() call
                        setMenuCollapsed(!isMenuCollapsed());

                        return false;
                    },
                    100,
                    { leading: true }
                )
            );

        // Mobile search
        const $mobileSearchButton = $('.jw-mobile-search-button');
        const $mobileSearch = $('.jw-mobile-menu-search');
        const $mobileSearchCancel = $mobileSearch.find('.js-cancel-search');
        const $mobileSearchInput = $mobileSearch.find(
            '.jw-mobile-menu-search__input'
        );

        $mobileSearchButton.off('click').on('click', () => {
            $mobileSearch.removeClass('jw-mobile-menu-search--hidden');
            setTimeout(() => $mobileSearchInput.focus(), 50);
        });

        $mobileSearchCancel.off('click').on('click', () => {
            $mobileSearch.addClass('jw-mobile-menu-search--hidden');
            $mobileSearchInput.blur();
        });

        let toggleFlag = false;
        $currentMenu
            .off('touchstart click')
            .on('touchstart click', '.jw-arrow', function () {
                if (!toggleFlag) {
                    toggleFlag = true;
                    setTimeout(() => {
                        toggleFlag = false;
                    }, 100);

                    const $item = $(this).closest('.jw-menu-item');
                    const $submenu = $item.children('.jw-submenu:first');

                    $item.toggleClass('jw-submenu-is-opened');
                    $submenu.slideToggle(200);
                }

                return false;
            });

        // Collapse mobile menu after clicking a link. If it's an anchor link that only jumps on the same page this
        // ensures that the overlay doesn't render over the new location in the page
        $currentMenu.on('click', '.jw-menu-link', () => {
            setMenuCollapsed(true);
        });
    }

    function reloadMobileMenu() {
        if (getState() !== 'mobile') {
            return;
        }

        // Fit header text to available space
        if (!$mobileHeaderText) {
            $mobileHeaderText = $('.jw-mobile-header .jw-mobile-text');
        }

        const { minFontSize = 16, maxFontSize = 30 } =
            config.templateConfig?.mobileHeaderText ?? {};

        if ($mobileHeaderText.length > 0 && $mobileHeaderText.is(':visible')) {
            const currentWidth = $mobileHeaderText[0].style.width;

            if (!currentWidth) {
                // if no explicit height is set on the element, try and use the
                // max-width instead
                $mobileHeaderText[0].style.width =
                    $mobileHeaderText.css('max-width');
            }

            // old Safari versions (<= 9) can't calculate width right after
            // setting width style, which cause textFit to crash. Just don't
            // use textFit in that case.
            if ($mobileHeaderText[0].clientWidth > 0) {
                // Apply max font-size so the container is at its maximum width.
                // This allows the font-size to grow again after shrinking.
                const innerSpan =
                    $mobileHeaderText[0].querySelector('.textFitted');
                if (innerSpan) {
                    innerSpan.style.fontSize = `${maxFontSize}px`;
                }

                textFit($mobileHeaderText[0], {
                    minFontSize,
                    maxFontSize,
                    widthOnly: true,
                });
            }

            $mobileHeaderText[0].style.width = currentWidth;
        }
    }

    function reloadMenu() {
        // Only decide on state of menu when the page is actually loaded. If
        // at this point the page has no width it is loaded and we will not test.
        if (document.documentElement.clientWidth === 0) {
            return;
        }

        const currentState = getState();

        $('body').removeClass('jw-is-menu-vertical-overflow');

        const newState = testPreferredState();
        $(window).trigger('jw.menu-reload');

        // No need to change
        if (currentState === newState) {
            return;
        }

        // Destroy old menu
        if (currentState === 'desktop') {
            destroyDesktopMenu();
        } else if (currentState === 'mobile') {
            destroyMobileMenu();
        }

        // Initialize new menu
        setState(newState);
        if (newState === 'desktop') {
            enableDesktopMenu();
        } else if (newState === 'mobile') {
            enableMobileMenu();
        }

        setTimeout(() => {
            // Dimension of slideshow could be changed. Thus reload it.
            if (window.JOUWWEB && window.JOUWWEB.reloadSlideshow) {
                window.JOUWWEB.reloadSlideshow();
            }

            // Call listeners after reload
            onMenuReloadListeners.forEach((fn) => fn());
        }, 1);
    }

    function debounce(fn, delay) {
        let timer = null;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                // only check for window width change, android and iphone trigger a resize event
                // when scrolling due to address-box hiding and showing
                if ($(window).width() !== currentWindowWidth) {
                    currentWindowWidth = $(window).width();
                    fn.apply(this, args);
                }
            }, delay);
        };
    }

    reloadAll = function () {
        reloadMenu();
        reloadDesktopMenu();
        reloadMobileMenu();
    };

    $(window).on('resize', debounce(reloadAll, 150));

    $(document).on('jw.loaded', reloadAll);
    $(document).on('jw.cart-mount', reloadAll);

    reloadMenu();

    // Layout of menu is affected by the loaded font. So we update again after
    // the fonts have loaded to ensure proper layout. See #2093.
    document.fonts?.ready?.then(reloadAll);
    setTimeout(reloadAll, 0); // fallback
});

export function addOnMenuReloadListener(fn) {
    onMenuReloadListeners.push(fn);
}

export function triggerMenuReload() {
    reloadAll?.();
}

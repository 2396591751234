import $ from 'jquery';

import { open } from 'website-rendering/photoswipe';
import {
    initPagination,
    paginate,
    setPageTotal,
    getPageCurrent,
} from 'website-rendering/helpers/pagination';
import scrollToElementTop from 'website-rendering/helpers/scollToElementTop';
import getObjectFitClientRect from './getObjectFitClientRect';

function getImagesPerPage($element) {
    return parseInt($element.find('.jw-album').attr('data-per-page'), 10);
}

export function setImagesPerPage($element, perPage) {
    $element.find('.jw-album').attr('data-per-page', perPage);
}

export function updateTotalPages($element, pageTotal) {
    const pagination = $element.find('.jw-pagination')[0];
    setPageTotal(pagination, pageTotal);
    paginate(pagination, getPageCurrent(pagination));
}

export function updateVisibleImages($element, page) {
    const perPage = getImagesPerPage($element);
    $element.find('.jw-album-image').each((index, el) => {
        const visible = page * perPage <= index && (page + 1) * perPage > index;
        $(el).toggleClass('hidden', !visible);
    });
}

export function onElementLoad($element) {
    const $images = $element.find('.jw-album-image');

    const pagination = $element.find('.jw-pagination')[0];
    initPagination(pagination, {
        onPaginate: async (page) => {
            scrollToElementTop($element[0]);
            await updateVisibleImages($element, page - 1);
        },
    });

    function openPhotoSwipe(index) {
        const $album = $element.find('.jw-album');
        const items = $images
            .map((i, el) => {
                const img = el.querySelector('.jw-album-image__image');

                return {
                    src: el.getAttribute('href'),
                    msrc: img.src,
                    w: parseInt(el.getAttribute('data-width'), 10),
                    h: parseInt(el.getAttribute('data-height'), 10),
                    title: el.getAttribute('data-caption'),
                };
            })
            .get();

        open(items, {
            index,
            captionEl: !$album.hasClass('jw-album--no-captions'),
            showHideOpacity: $album.hasClass('jw-album--display-format-cover'),
            getThumbBoundsFn: (i) => {
                const img = $images.eq(i).find('.jw-album-image__image')[0];
                const scrollY = window.pageYOffset;
                const { x, y, width } = getObjectFitClientRect(img);
                return { x, y: y + scrollY, w: width };
            },
        }).then((gallery) => {
            // Update current page when changing image
            gallery.listen('beforeChange', () => {
                const perPage = getImagesPerPage($element);
                const index = gallery.getCurrentIndex();
                const page = Math.floor(index / perPage) + 1;

                paginate(pagination, page);
                updateVisibleImages($element, page - 1);
            });
        });
    }

    $images.on('click', function (e) {
        e.preventDefault();
        openPhotoSwipe($images.index(this));
    });
}

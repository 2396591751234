// See https://stackoverflow.com/questions/21751377/foolproof-way-to-detect-if-this-page-is-inside-a-cross-domain-iframe
function isCrossOriginFrame() {
    try {
        return !window.top?.location.hostname;
    } catch (e) {
        return true;
    }
}

export function isInAccessibleFrame(): boolean {
    // Do not crash whenever page is embedded in cross-origin iframe
    return !isCrossOriginFrame() && window.location !== window.parent.location;
}

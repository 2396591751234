import $ from 'jquery';

import type { ProductImage, ProductVariant } from 'app/features/webshop/types';

import getObjectFitClientRect from 'website-rendering/elements/album-raster/getObjectFitClientRect';
import { responsiveContainerSelector } from 'website-rendering/features/responsive-containers/helpers';
import { open } from 'website-rendering/photoswipe';

import type { HasSelectedVariant } from './HasSelectedVariant';

interface SlideItem {
    image: ProductImage;
    thumbnailElement: HTMLAnchorElement | null;
    slideElement: HTMLAnchorElement;
    slideImageElement: HTMLImageElement;
}

/**
 * @param slideElement - The `.image-gallery__slide-item` element.
 * @param thumbnailElement - The `.image-gallery__thumbnail-item` element.
 * @param active
 */
function toggleSlideActive(
    slideElement: HTMLAnchorElement,
    thumbnailElement: HTMLAnchorElement | null,
    active: boolean
): void {
    // Ensure only the active slide can be focused
    slideElement.tabIndex = active ? 0 : -1;

    // Highlight thumbnail for the current slide
    thumbnailElement?.classList.toggle(
        'image-gallery__thumbnail-item--active',
        active
    );

    // Thumbnail representing the current slide is disabled for screen readers
    thumbnailElement?.setAttribute('aria-disabled', active ? 'true' : 'false');
}

interface ProductImageGalleryContainerOptions {
    withPhotoSwipe?: boolean;
}

/**
 * Container for interacting with images of products elements with a more
 * complex image gallery and slider. This is currently just on product pages.
 */
export class ProductImageGalleryContainer implements HasSelectedVariant {
    private slideItems: SlideItem[];

    private slider: any;
    private sliderInitialized = false;
    private initialSlideIndex: null | number = null;

    private mainElement: HTMLElement | null;
    private prevSlideControl: HTMLButtonElement | null;
    private nextSlideControl: HTMLButtonElement | null;
    private slidesContainer: HTMLElement;

    constructor(
        element: HTMLElement,
        options?: ProductImageGalleryContainerOptions
    ) {
        this.mainElement = document.querySelector<HTMLElement>(
            '.image-gallery__main'
        );

        this.prevSlideControl = element.querySelector<HTMLButtonElement>(
            '.image-gallery__control--prev'
        );
        this.nextSlideControl = element.querySelector<HTMLButtonElement>(
            '.image-gallery__control--next'
        );

        const slideElements = element.querySelectorAll<HTMLAnchorElement>(
            '.image-gallery__slide-item'
        );
        this.slideItems = Array.from(
            slideElements,
            (slideElement): SlideItem => {
                const image: ProductImage = {
                    id: Number(slideElement.dataset.imageId),
                    url: slideElement.href,
                    width: Number(slideElement.dataset.width),
                    height: Number(slideElement.dataset.height),
                };

                const thumbnailElement =
                    element.querySelector<HTMLAnchorElement>(
                        `.image-gallery__thumbnail-item[data-image-id="${image.id}"]`
                    );
                const slideImageElement = slideElement.querySelector('img');
                if (!slideImageElement) {
                    throw new Error('slide does not contain an image element');
                }

                return {
                    image,
                    thumbnailElement,
                    slideElement,
                    slideImageElement,
                };
            }
        );

        const slidesContainer = element.querySelector<HTMLElement>(
            '.image-gallery__slides'
        );
        if (!slidesContainer) {
            throw new Error('no slides container element');
        }
        this.slidesContainer = slidesContainer;

        void this.initialize(element, options);
    }

    private async initialize(
        element: HTMLElement,
        options?: ProductImageGalleryContainerOptions
    ): Promise<void> {
        await import(
            // @ts-expect-error: TS7016 because bxSlider is old and untyped
            /* webpackChunkName: "bxslider" */ 'bxslider/dist/jquery.bxslider'
        );

        // @ts-expect-error: TS2551 because bxSlider is old and untyped
        this.slider = $(this.slidesContainer).bxSlider({
            pager: false,
            controls: false,
            infiniteLoop: false,

            // TODO: bxSlider's touch gestures break click events on slides and
            //  breaks page scrolling on touch devices. So disable it for now.
            //  --- We should look into a different slider library.
            touchEnabled: false,

            slideMargin: 8,

            // aria-live is set in our HTML
            ariaLive: false,
            // aria-disabled could be managed by us in onSlideBefore, but this also works
            ariaDisabled: true,

            onSliderLoad: () => {
                // Disable interaction with cloned slides
                const clonedSlideElements =
                    this.slidesContainer.querySelectorAll<HTMLAnchorElement>(
                        '.bx-clone .image-gallery__slide-item'
                    );
                clonedSlideElements.forEach((clonedSlideElement) => {
                    toggleSlideActive(clonedSlideElement, null, false);
                });
            },

            onSlideBefore: (
                $slider: JQuery,
                oldIndex: number,
                newIndex: number
            ) => {
                const oldSlideItem = this.slideItems[oldIndex];
                const newSlideItem = this.slideItems[newIndex];

                toggleSlideActive(
                    oldSlideItem.slideElement,
                    oldSlideItem.thumbnailElement,
                    false
                );
                toggleSlideActive(
                    newSlideItem.slideElement,
                    newSlideItem.thumbnailElement,
                    true
                );

                const isFirstSlide = newIndex === 0;
                const isLastSlide = newIndex === this.slideItems.length - 1;
                this.prevSlideControl?.classList.toggle(
                    'image-gallery__control--disabled',
                    isFirstSlide
                );
                this.nextSlideControl?.classList.toggle(
                    'image-gallery__control--disabled',
                    isLastSlide
                );
            },
        });

        this.slideItems.forEach((slideItem, index) => {
            // Click thumbnail -> go to corresponding slide
            slideItem.thumbnailElement?.addEventListener('click', (e) => {
                e.preventDefault();
                this.slider.goToSlide(index);
            });

            if (options?.withPhotoSwipe) {
                // Click slide -> open PhotoSwipe
                slideItem.slideElement.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.openPhotoSwipe(slideItem.image);
                });
            }
        });

        // Prev/next navigation controls
        this.prevSlideControl?.addEventListener('click', () => {
            this.slider.goToPrevSlide();
        });
        this.nextSlideControl?.addEventListener('click', () => {
            this.slider.goToNextSlide();
        });

        // Redraw slider when responsive container is resized
        const responsiveContainer = element.closest(
            responsiveContainerSelector
        );
        if (responsiveContainer) {
            $(responsiveContainer).on('jw.responsive-container-resize', () => {
                this.slider.redrawSlider();
            });
        }

        this.sliderInitialized = true;

        if (this.initialSlideIndex !== null) {
            this.goToSlide(this.initialSlideIndex);
        }
    }

    private goToSlide(slideIndex: number) {
        if (!this.sliderInitialized) {
            this.initialSlideIndex = slideIndex;
            return;
        }

        this.slider.goToSlide(slideIndex);
    }

    setSelectedVariant(selectedVariant: ProductVariant): void {
        // Show image corresponding to selected product variant
        const selectedVariantImage = selectedVariant.image;
        if (selectedVariantImage) {
            const selectedIndex = this.slideItems.findIndex(
                (slideItem) => slideItem.image.id === selectedVariantImage.id
            );
            if (selectedIndex !== -1) {
                this.goToSlide(selectedIndex);
            }
        }
    }

    private async openPhotoSwipe(startImage?: ProductImage) {
        const hideAnimationDuration = 333;

        const pswp = await open(
            this.slideItems.map((slideItem) => ({
                src: slideItem.image.url,
                msrc: slideItem.slideImageElement.currentSrc,
                w: slideItem.image.width ?? undefined,
                h: slideItem.image.height ?? undefined,
            })),
            {
                index: startImage
                    ? this.slideItems.findIndex(
                          (slideItem) => slideItem.image.id === startImage.id
                      )
                    : 0,

                getThumbBoundsFn: (index: number) => {
                    const { slideImageElement } = this.slideItems[index];
                    const scrollY = window.pageYOffset;
                    const { x, y, width, height } =
                        getObjectFitClientRect(slideImageElement);
                    return { x, y: y + scrollY, w: width, height };
                },

                hideAnimationDuration,
            }
        );

        // Focus current slide (link) when PhotoSwipe closes
        pswp.listen('close', () => {
            const currentSlideIndex = this.slider.getCurrentSlide();
            this.slideItems[currentSlideIndex].slideElement.focus();
        });

        // Hide image in main slider while PhotoSwipe is open
        let initialZoomOutTimeout = -1;
        pswp.listen('initialZoomIn', () => {
            clearTimeout(initialZoomOutTimeout);
            this.mainElement?.style.setProperty('opacity', '0');
        });
        pswp.listen('initialZoomOut', () => {
            initialZoomOutTimeout = window.setTimeout(() => {
                this.mainElement?.style.setProperty('opacity', '1');
            }, hideAnimationDuration);
        });

        // Sync current image between PhotoSwipe and main slider
        pswp.listen('beforeChange', () => {
            const currentSlideIndex = pswp.items.indexOf(pswp.currItem);
            this.slider.goToSlide(currentSlideIndex);
        });
    }
}

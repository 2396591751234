/**
 * Adds/removes a loading indicator on the given button.
 *
 * @param {HTMLElement} button
 * @param {boolean} [loading=true]
 */
export function setButtonLoading(button, loading = true) {
    button.disabled = loading;

    let spinner = button.querySelector('.jw-spinner');
    if (loading && !spinner) {
        spinner = document.createElement('span');
        spinner.className = 'jw-spinner';
        button.appendChild(spinner);
    } else if (spinner && !loading) {
        button.removeChild(spinner);
    }
}

export function registerFormSubmitHandlers() {
    document.addEventListener('submit', (event) => {
        event.target
            .querySelectorAll('button[data-loading-trigger=submit]')
            .forEach((button) => {
                setButtonLoading(button, true);
            });
    });
}

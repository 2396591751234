import { registerScrollEffect } from 'website-rendering/scroll-effects';

let fixedHeaderCheckDone = false;

function updateScrollEffect({ scrollY }: { scrollY: number }) {
    // Disable this behaviour in the editor; e.g. a fixed header can block content
    if (document.body.classList.contains('jw-is-editor')) {
        return;
    }

    // Add affix class when the page has been scrolled
    document.body.classList.toggle('jw-is-header-affix', scrollY > 0);

    // Only check once for performance reasons
    if (!fixedHeaderCheckDone && scrollY > 0) {
        const header = document.querySelector('.js-fixed-header-container');
        if (
            header &&
            ['fixed', 'sticky'].includes(
                window
                    .getComputedStyle(header)
                    .getPropertyValue('position')
                    .toLowerCase()
            )
        ) {
            // This variable is used by CSS to make sure elements scrolled into
            // view (.scrollIntoView()) don't end up below the fixed header
            document.documentElement.style.setProperty(
                '--fixed-header-height',
                `${header.clientHeight ?? 0}px`
            );
        }
        fixedHeaderCheckDone = true;
    }
}

registerScrollEffect(updateScrollEffect);

import { domReady } from 'common/dom';
import { initVideoBannerResizer } from './video';

domReady().then(() => {
    const wrapper = document.querySelector('.jw-slideshow-slide-video');
    if (wrapper) {
        const container = wrapper.parentNode;
        initVideoBannerResizer(container);
    }
});

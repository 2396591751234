import type { Options as CookieConsentOptions } from 'cookieconsent';

import { type ColorString } from 'lib/tinycolor';

import {
    type ExperimentDefaults,
    type ExperimentEnrollments,
} from 'common/experiments';
import { type Money } from 'common/money/types';
import { type Plan } from 'common/subscription';

import { type SegmentBlock } from 'app/blocks/types';
import type { Brick } from 'app/editor-bricks/types';
import type { TourTriggerUnion } from 'app/features/tour/types';
import {
    type SimpleShippingMethod,
    type TaxOption,
} from 'app/features/webshop/types';

window.JOUWWEB = window.JOUWWEB || {};

const jwGlobal: GlobalConfig = window.JOUWWEB;
export default jwGlobal;

// Application
jwGlobal.application = jwGlobal.application || {};

if (!jwGlobal.application.editorLocale) {
    jwGlobal.application.editorLocale = 'en-US';
}

// Experiments
jwGlobal.experiment = jwGlobal.experiment || {};

// Website rendering
jwGlobal.websiteRendering = jwGlobal.websiteRendering || {};

if (!jwGlobal.websiteRendering.locale) {
    jwGlobal.websiteRendering.locale = 'en-GB';
}

// Website
jwGlobal.website = jwGlobal.website || {};
jwGlobal.website.allowed = jwGlobal.website.allowed || {};
jwGlobal.website.mobileBar = jwGlobal.website.mobileBar || {};

if (!jwGlobal.website.id) {
    jwGlobal.website.id = null;
}

if (!jwGlobal.website.allowed.legacyFontSize) {
    jwGlobal.website.allowed.legacyFontSize = false;
}

if (!jwGlobal.website.mobileBar.email) {
    jwGlobal.website.mobileBar.email = {
        default: '',
    };
}

// Webshop
jwGlobal.website.webshop = jwGlobal.website.webshop || {};

export interface GlobalConfig {
    application: ApplicationConfig;
    editor: EditorConfig;
    experiment: ExperimentConfig;
    page: PageConfig;
    url: Record<string, any>;
    /** User may not be available even within the editor. For example in the website invite system. */
    user?: UserConfig;
    sentryEnabledForJavascript: boolean;
    website: WebsiteConfig;
    websiteRendering: WebsiteRenderingConfig;
    brand: BrandConfig;
    localization: Record<string, any>;
    landing?: LandingConfig;
    cookieConsent?: CookieConsentOptions;
    error?: { status: number };
    colorPalette?: ColorString[];
    /** Used to load scripts on website-rendering pages. */
    scripts?: string[];
    childFrame?: { lessUrl: string };
    /** Optional configuration defined by templates. */
    templateConfig?: Record<string, any>;
}

export interface ApplicationConfig {
    editorLocale: string;
    editorTimezone: string;
    analytics4TrackingId: string;
    analyticsUniversalTrackingId: string;
    backendDomain: string;
    backendShortDomain: string;
    backendKey: string;
    freeWebsiteDomain: string;
    environment: string;
    assetsUrl: string;
    /**
     * Localized login url (we otherwise only provide localized routing in
     * website contexts).
     */
    loginUrl: string;
    build: {
        bust: string;
        reference: string;
    };
    noSsl: boolean;
    developer: boolean;

    pricing: {
        // TODO the index here is a plan identifier, not an arbitrary string
        plans: Record<Plan, Money>;
        yearlyDiscount: {
            price: Money;
            ratio: number;
        };
    };
}

export interface EditorConfig {
    imageExtensions: string[];
    imageMaxSize: number;
    audioExtensions: string[];
    audioMaxSize: number;
    videoExtensions: string[];
    videoMaxSize: number;
    documentExtensions: string[];
    documentMaxSize: number;
    websiteUploadExtensions: string[];
    websiteUploadMaxSize: number;
    euCountryCodes: string[];
    taxCountryCodes: string[];
    block: SegmentBlock;
    elements: Brick[];
}

export interface PageConfig {
    id?: number;
    segment: string;
    title: string;
    editable: boolean;
    locale: string;
    fillStatus: string;
}

export interface UserConfig {
    administrator?: true;
    claimed: boolean;
    email: string;
    id: number;
    triggerTour: TourTriggerUnion | null;
}

export interface WebsiteRenderingConfig {
    locale: string;
    timezone: string;
    // Keep in sync with backend WebsiteRenderingConfig.
    routes: {
        checkout: string;
        'api/upload/product-field': string;
        payment: string;
        'payment/forward': string;
        'public-order': string;
        'checkout/authorize': string;
    };
}

/**
 * PHP: Website::CATEGORIES
 */
export enum WebsiteCategory {
    WEBSITE = 'website',
    WEBSHOP = 'webshop',
    BLOG = 'blog',
}

/**
 * Only includes permissions that have a value to keep model small and on a
 * need-to-know basis.
 *
 * PHP: PermissionSet::toArray()
 */
export interface WebsitePermissionSet {
    ads?: boolean;
    credits?: boolean;
    slideshow?: boolean;
    hostedAlbums?: boolean;
    moderators?: boolean;
    freeMailAccounts?: number;
    mailboxQuota?: number;
    statistics?: boolean;
    favicon?: boolean;
    password?: boolean;
    freeDomains?: number;
    canUseLanguages?: boolean;
    fileUpload?: boolean;
    legacyFontSize?: boolean;
    webshop?: boolean;
    products?: number;
    imageText?: boolean;
    search?: boolean;
    audioUpload?: boolean;
    videoUpload?: number;
    allowDangerousForms?: boolean;
    statisticsMonths?: number;
    allowHtmlCode?: boolean;
    mobileBar?: boolean;
    sidebar?: boolean;
    poll?: boolean;
    allowCustomForms?: boolean;
    allowBusinessListing?: boolean;
    allowCustomAnalytics?: boolean;
    allowAccountingLink?: boolean;
    digitalProducts?: boolean;
}

/**
 * PHP: WebsiteToArray::__invoke()
 */
export interface WebsiteConfig {
    id: number | null;
    enabled: boolean;
    title: string;

    /**
     * Indicates whether the website has a user-defined title.
     * (as opposed to our default fallback "Website title" title when the user
     * never titles their website)
     */
    hasTitle: boolean;
    roleOfLoggedInUser: WebsiteRole | null;
    /** @deprecated Try to use permission checks where possible. */
    plan: Plan | null;
    backendKey: string;
    freeWebsiteDomain: string;
    migrated: boolean;
    defaultLocale: string;
    allowed: WebsitePermissionSet;
    webshop: WebshopConfig;
    /** Always includes trailing slash. Combine via `createWebsiteUrl()`. */
    url: string;
    mobileBar: MobileBarConfig;
    homepageSegmentId: number;
    homepageSegmentType: string;
    currency: string;
    locales: string[];
    category?: WebsiteCategory;
    isOffline: boolean;
}

export enum WebsiteRole {
    PAGE_EDITOR = 'page-editor',
    CO_OWNER = 'co-owner',
    OWNER = 'owner',
}

/**
 * PHP: WebsiteToArray::webshopToArray()
 */
export interface WebshopConfig {
    enabled: boolean;
    currency: string;
    taxEnabled: boolean;
    taxInclusive: boolean;
    vatDisclaimerVisible: boolean;
    orderNotice: string;
    orderConfirmation: string;
    freeShipping: boolean;
    freeShippingAmount: string;
    shippingDisclaimerVisible: boolean;
    shippingPageId: number | null;
    pickupAllowed: boolean;
    couponAllowed: boolean;
    detailsPageAvailable: boolean;
    socialMediaVisible: boolean;
    termsPage: number | null;
    termsPageUrl: string | null;
    pricingVisible: boolean;
    orderButtonVisible: boolean;
    shippingAdvanced: boolean;
    shippingAdvancedBackEnd: boolean;
    soldOutVisible: boolean;
    canAddProducts: boolean;
    nextOrderNumber: number;
    simpleShippingMethods: SimpleShippingMethod[]; // Only in editor
    taxOptions: TaxOption[]; // Only in editor
    maxOrderNumber: number; // Only in editor
    allowedServicePoints: string[];
    sendcloudConfigured: boolean;
    sendcloudFallbackPublicKey: string | null;
    sendcloudCarriers: string[]; // Only in editor
    sendcloudFromPrice: string; // Only in editor
    invoiceComment: string | null;
    emptyCartVisible: boolean;
    minimumOrderPrice: string | null;
    suggestedProductIds: number[]; // Only in editor
    productNumbersEnabled: boolean;
    wishlistEnabled: boolean;
    countryCode: string | null; // Only in editor
    countrySubdivisionCode: string | null; // Only in editor
    placeholderTaxRate: number | null; // Only in editor
    backInStockNotificationEnabled: boolean;
    itrkToken: string | null; // Only in editor
}

export interface MobileBarConfig {
    enabled: boolean;
    theme: string;
    email: { active: boolean; value: string } | { default: string };
    location: { active: boolean; value: string };
    phone: { active: boolean; value: string };
    social: { active: boolean; value: string; network: string };
    whatsapp: { active: boolean; value: string };
}

export interface BrandConfig {
    type: string;
    name: string;
    domain: string;
    supportEmail: string;
}

export interface ExperimentConfig {
    enrollments: ExperimentEnrollments;
    /** Mapping from experiment name to default variant when it is not "control".  */
    defaults: ExperimentDefaults;
}

export interface LandingConfig {
    category: WebsiteCategory;
    route?: string;
}

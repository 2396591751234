export default class ProductFilter {
    /** @type HTMLElement */
    #element;

    /** @type HTMLButtonElement */
    #toggleButton;

    /** @type HTMLElement */
    #popoverContainer;

    /** @type HTMLElement */
    #popoverContent;

    /** @type NodeListOf<HTMLInputElement> */
    #checkboxes;

    /** @type HTMLButtonElement */
    #submitButton;

    /** @type string */
    #name;

    /** @type function(string, string[]): void */
    #onFilter;

    /**
     * @param {HTMLElement} element
     * @param {function(string, string[]): void} onFilter
     */
    constructor(element, { onFilter }) {
        this.#onFilter = onFilter;

        this.#element = element;
        this.#toggleButton = element.querySelector('.js-filter-toggle');
        this.#popoverContainer = element.querySelector('.jw-popover-container');
        this.#popoverContent = element.querySelector('.jw-popover__content');
        this.#checkboxes = element.querySelectorAll('input[type="checkbox"]');
        this.#submitButton = element.querySelector('.js-filter-submit');

        const fieldset = element.querySelector('fieldset');
        this.#name = fieldset.getAttribute('name');

        this.#toggleButton.addEventListener('click', this.#togglePopover);
        this.#submitButton.addEventListener('click', this.#applyFilter);
    }

    getName() {
        return this.#name;
    }

    getCheckedOptions() {
        let options = [];
        this.#checkboxes.forEach((el) => {
            if (el.checked) {
                options.push(el.name);
            }
        });
        return options;
    }

    /**
     * Closes popover if the event target is outside the filter elements.
     * @param {Event} e
     */
    #closeIfTargetOutside = (e) => {
        if (!this.#element.contains(e.target)) {
            this.#closePopover();
        }
    };

    #togglePopover = () => {
        const isHidden = this.#popoverContainer.classList.contains('is-hidden');
        if (isHidden) {
            this.#openPopover();
        } else {
            this.#closePopover();
        }
    };

    #openPopover = () => {
        this.#popoverContainer.classList.remove('is-hidden');
        this.#toggleButton.setAttribute('aria-expanded', 'true');

        // Align popover to the right of the container if the popover would go
        // outside of the viewport. The UNSAFE_MARGIN is the maximum distance
        // the container's left side should have from the <body>'s right side.
        // It's hardcoded to be 210 pixels, because we can't calculate the
        // popover content's actual width without hacks (because of transitions
        // on .is-hidden).
        // TODO find a cleaner solution where we don't have to rely on hardcoded
        // values.
        const UNSAFE_MARGIN = 210;
        const bodyRect = document.body.getBoundingClientRect();
        const elRect = this.#popoverContainer.getBoundingClientRect();
        this.#popoverContent.classList.toggle(
            'jw-popover__content--align-right',
            elRect.left + UNSAFE_MARGIN >= bodyRect.right
        );

        document.addEventListener('click', this.#closeIfTargetOutside);
        document.addEventListener('focusin', this.#closeIfTargetOutside);
    };

    #closePopover = () => {
        this.#popoverContainer.classList.add('is-hidden');
        this.#toggleButton.setAttribute('aria-expanded', 'false');
        document.removeEventListener('click', this.#closeIfTargetOutside);
        document.removeEventListener('focusin', this.#closeIfTargetOutside);
    };

    #applyFilter = () => {
        this.#onFilter(this.getName(), this.getCheckedOptions());
        this.#closePopover();
    };
}

import { isInAccessibleFrame } from '../isInAccessibleFrame';

import { makeFrameService } from './makeFrameService';

declare global {
    interface ParentFrameService {}
}

export const {
    getFrameService: getParentFrameService,
    extendFrameService: extendParentFrameService,
} = makeFrameService<ParentFrameService>(
    'parentFrameService',
    // @ts-expect-error All properties should be made optional, since they
    //  are defined asynchronously, but this would break a lot of existing
    //  assumptions.
    {},
    getMainWindow
);

function getMainWindow() {
    return isInAccessibleFrame() ? window.parent : window;
}

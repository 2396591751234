/* eslint-disable import/order -- Almost every import here has side effects */

import 'superfish';

// load jquery-hoverIntent -- it's not used by us, but it will be detected and
// used by superfish
import 'jquery-hoverintent/jquery.hoverIntent';

import 'common/unsafe-auth';

import './header/frontend';

import './elements/album-raster';
import 'common/accordion';
import './elements/comments';
import './elements/download';
import './elements/image';
import './elements/map';
import './elements/poll';
import './elements/rating';
import './elements/social-share';
import './elements/banner';
import './elements/document';
import './elements/contact-form';
import './elements/product-gallery';
import './elements/news';
import './elements/search';

import { initResponsiveContainers } from './features/responsive-containers/helpers';

import { registerFormSubmitHandlers } from './helpers/loading';

import './menu';
import './search';
import './slideshow/scroll-effects';
import './bottom-bar/scroll-effects';
import './bottom-bar/mobile-bar/loader';

import './website-rendering-async';

import { createPopup } from 'common/cookie-consent';
import { domReady } from 'common/dom';
import config from '../common/config';

domReady().then(() => {
    initResponsiveContainers();

    if (config.cookieConsent && config.cookieConsent?.autoOpen) {
        createPopup({
            ...config.cookieConsent,
            onStatusChange(status) {
                // TODO This could eventually move into a listener. But given
                //   this is the only thing that the cookiebar does, we are
                //   fine for now.

                if (status === 'allow' && window?.gtag) {
                    window.gtag('consent', 'update', {
                        ad_user_data: 'granted',
                        ad_personalization: 'granted',
                        ad_storage: 'granted',
                        analytics_storage: 'granted',
                    });
                }
            },
        }).then((popup) => popup.open());
    }

    registerFormSubmitHandlers();
});

import type { ProductVariant } from 'app/features/webshop/types';

import { open } from 'website-rendering/photoswipe';

import type { HasSelectedVariant } from './HasSelectedVariant';

/**
 * Container for interacting with images of products elements with a single,
 * simple image element.
 */
export class ProductImageContainer implements HasSelectedVariant {
    private element: HTMLElement;
    private mainImageElement: HTMLImageElement;

    constructor(element: HTMLElement) {
        this.element = element;

        const dataPhotoSwipeItems = element.getAttribute(
            'data-photoswipe-items'
        );
        if (dataPhotoSwipeItems) {
            const photoswipeItems = JSON.parse(dataPhotoSwipeItems) as {
                src: string;
                w: number;
                h: number;
            }[];

            element.addEventListener('click', () => {
                void open(photoswipeItems);
            });
        }

        const mainImageElement = element.querySelector<HTMLImageElement>(
            '.product-image__image--main'
        );
        if (!mainImageElement) {
            throw new Error('no main image element');
        }
        this.mainImageElement = mainImageElement;
    }

    setSelectedVariant(selectedVariant: ProductVariant): void {
        // Remove images of other variants that may be visible
        this.element
            .querySelectorAll<HTMLImageElement>(
                '.product-image__image--variant'
            )
            .forEach((img) => {
                img.remove();
            });

        // If selected variant has no specified image, just show the main image
        if (!selectedVariant.image) {
            this.mainImageElement.style.visibility = 'visible';
            return;
        }

        // Otherwise, hide the main image...
        this.mainImageElement.style.visibility = 'hidden';

        // ... and show the image for the selected variant
        const variantImageElement = document.createElement('img');
        variantImageElement.classList.add(
            'product-image__image',
            'product-image__image--variant'
        );
        variantImageElement.src = selectedVariant.image.url;
        // TODO: Add srcset for selected variants
        variantImageElement.sizes = this.mainImageElement.sizes;
        if (selectedVariant.image.width && selectedVariant.image.height) {
            variantImageElement.width = selectedVariant.image.width;
            variantImageElement.height = selectedVariant.image.height;
        }
        this.element.append(variantImageElement);
    }
}

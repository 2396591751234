import qs from 'qs';

/**
 * Replace an element's parameters in the current URL.
 *
 * @param {string} elementId
 * @param {object} elementParams
 */
export function replaceElementParams(elementId, elementParams) {
    const parsedParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
        parseArrays: false,
    });

    const newParams = {
        ...parsedParams,
        ep: {
            ...parsedParams.ep,
            [elementId]: elementParams,
        },
    };

    const newSearch = qs.stringify(newParams, {
        addQueryPrefix: true,
    });

    const newHref = window.location.pathname + newSearch;
    window.history.replaceState({}, window.title, newHref);
}

/**
 * Configure MomentJS without actually requiring MomentJS.
 * Import MomentJS via `import moment from "lib/moment";`
 * (this makes it easier to only import Moment where needed)
 */
type MomentConfig = {
    locale: string;
    timezone: string;
};

let config: MomentConfig | undefined;

/**
 * Configure date and timezone for "lib/moment" module.
 */
export function configure(newConfig: MomentConfig): void {
    config = newConfig;
}

/**
 * Get config for "lib/moment" module.
 */
export function getConfig(): MomentConfig | undefined {
    return config;
}

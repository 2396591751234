import { postJSON } from '../../common/http';
import { ProductVariantWithProperties } from 'app/features/webshop/types';

interface BackInStockRegisterResponse {
    success: boolean;
}

export class BackInStockInput {
    private readonly element: HTMLElement;
    private readonly form: HTMLFormElement;
    private readonly email: HTMLInputElement;
    private readonly productVariant: HTMLInputElement;

    constructor(element: HTMLElement) {
        this.element = element;
        this.form = this.element.querySelector('form')!;

        this.email = this.element.querySelector('input[name="email"]')!;
        this.productVariant = this.element.querySelector(
            'input[name="productVariant"]'
        )!;

        this.form.addEventListener('submit', this.register.bind(this));
    }

    update(variant: ProductVariantWithProperties): void {
        this.productVariant.value = String(variant.id);
        this.element.classList.toggle(
            'hidden',
            !variant.limited || variant.stock > 0
        );

        this.element
            .querySelector<HTMLElement>('.back-in-stock__form')!
            .classList.remove('hidden');
        this.element
            .querySelector<HTMLElement>('.back-in-stock__thanks')!
            .classList.add('hidden');
    }

    async register(event: SubmitEvent): Promise<BackInStockRegisterResponse> {
        event.preventDefault();
        const url = '_api/webshop/back-in-stock/register';
        const response = await postJSON<BackInStockRegisterResponse>(url, {
            email: this.email.value,
            productVariant: this.productVariant.value,
        });

        this.element
            .querySelector<HTMLElement>('.back-in-stock__form')!
            .classList.add('hidden');
        this.element
            .querySelector<HTMLElement>('.back-in-stock__thanks')!
            .classList.remove('hidden');

        return response;
    }
}

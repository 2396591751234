import { forEach } from 'lodash-es';

import { sprintf, w } from 'common/i18n/website-rendering';

function getPageLinks(element: HTMLElement) {
    return element.querySelectorAll<HTMLAnchorElement>('[data-page]');
}

function getPrev(element: HTMLElement) {
    return element.querySelector<HTMLAnchorElement>('[data-page-prev]');
}

function getNext(element: HTMLElement) {
    return element.querySelector<HTMLAnchorElement>('[data-page-next]');
}

function getSmall(element: HTMLElement) {
    return element.querySelector<HTMLElement>('.jw-pagination__small');
}

export function getPageCurrent(element: HTMLElement): number {
    return parseInt(element.dataset.pageCurrent ?? '', 10);
}

function setPageCurrent(element: HTMLElement, page: number) {
    element.dataset.pageCurrent = page.toString();
}

function getPageTotal(element: HTMLElement) {
    return parseInt(element.dataset.pageTotal ?? '', 10);
}

export function setPageTotal(element: HTMLElement, total: number): void {
    element.dataset.pageTotal = String(total);
}

interface Pagination {
    /**
     * Re-binds this pagination to another element. Useful when the entire HTML
     * of an element is replaced.
     */
    replaceElement(newElement: HTMLElement): void;
}

interface PaginationOptions {
    /**
     * This callback is called when the user clicks on one of the links in the
     * pagination UI. It should be used to update the paginated content to the
     * correct page.
     */
    onPaginate?: (page: number) => Promise<void>;
}

/**
 * Initializes the pagination UI for the HTML in the given `element`. Actual
 * pagination of the content must be done by the caller in the `onPaginate`
 * callback.
 */
export function initPagination(
    element: HTMLElement,
    { onPaginate }: PaginationOptions
): Pagination {
    function innerPaginate(page: number, relative = false) {
        if (relative) {
            page = getPageCurrent(element) + page;
        }
        page = Math.max(1, Math.min(getPageTotal(element), page));

        Promise.resolve()
            .then(async () => {
                if (onPaginate) {
                    // NOTE: This callback may call replaceElement if the HTML
                    //  is overwritten with new HTML from the server.
                    await onPaginate(page);
                }
            })
            .then(() => {
                paginate(element, page);
            });
    }

    function handlePageClick(e: MouseEvent) {
        fakeLinkClick(e);

        const anchor = e.currentTarget as HTMLAnchorElement;
        const page = parseInt(anchor.dataset.page ?? '', 10);
        innerPaginate(page);
    }

    function fakeLinkClick(e: MouseEvent) {
        const anchor = e.currentTarget as HTMLAnchorElement;

        e.preventDefault();

        if ('replaceState' in window.history) {
            // use `replaceState` instead of `pushState` -- we don't really
            // need previous/next support for pagination *within* the page right
            // now. We only want to be able to return to the correct page when
            // leaving the page and then going back
            window.history.replaceState({}, '', anchor.href);
        }
    }

    function bindEvents() {
        forEach(getPageLinks(element), (pageLink: HTMLElement) => {
            pageLink.addEventListener('click', handlePageClick);
        });

        getPrev(element)?.addEventListener('click', (e) => {
            fakeLinkClick(e);
            innerPaginate(-1, true);
        });
        getNext(element)?.addEventListener('click', (e) => {
            fakeLinkClick(e);
            innerPaginate(+1, true);
        });
    }

    bindEvents();

    return {
        replaceElement(newElement) {
            element = newElement;
            bindEvents();
        },
    };
}

/**
 * Updates the pagination UI in the given `element` to the given page `newPage`.
 */
export function paginate(element: HTMLElement, activePage: number): void {
    // NOTE: Frontend pagination logic should be kept in sync with the backend logic!

    const pageTotal = getPageTotal(element);

    activePage = Math.max(1, Math.min(pageTotal, activePage));

    setPageCurrent(element, activePage);

    element.classList.toggle('hidden', pageTotal <= 1);

    // Prev/next buttons
    getPrev(element)?.parentElement?.classList.toggle(
        'jw-pagination__control--hidden',
        activePage <= 1
    );
    getNext(element)?.parentElement?.classList.toggle(
        'jw-pagination__control--hidden',
        activePage >= pageTotal
    );

    // Small overview
    const small = getSmall(element);
    if (small) {
        small.innerHTML = `(${activePage} / ${pageTotal})`;
    }

    // Update all page link elements. Note that this can fully (un)hide
    // previously existing pages to support changes to elements in the editor.
    const pageLinks = getPageLinks(element);
    pageLinks.forEach((pageLink) => {
        const page = parseInt(pageLink.dataset.page ?? '', 10);

        // Accessibility
        const label = sprintf(
            page === activePage ? w('Current page, page %d') : w('Page %d'),
            page
        );
        pageLink.setAttribute('title', label);
        pageLink.setAttribute('aria-label', label);
        pageLink.setAttribute('aria-current', (page === activePage).toString());

        // Active class
        pageLink.classList.toggle(
            'jw-pagination__page--active',
            page === activePage
        );

        // Responsive hidden classes
        let hiddenClass = 'hidden-lt300';
        if (page !== activePage) {
            if (
                page !== 1 &&
                page !== pageTotal &&
                !(page <= 3 && activePage < 3) &&
                !(page >= pageTotal - 2 && activePage > pageTotal - 2)
            ) {
                hiddenClass = 'hidden-lt400';

                // Completely hide pages before, after or outside visible range.
                const start = Math.max(
                    1,
                    Math.min(activePage - 2, pageTotal - 4)
                );
                if (page < start || page >= start + 5 || page > pageTotal) {
                    hiddenClass = 'hidden';
                }
            }
        }

        const pageItem = pageLink.parentElement;
        if (pageItem) {
            pageItem.className = `jw-pagination__item ${hiddenClass}`;
        }
    });
}

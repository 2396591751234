import type PhotoSwipe from 'photoswipe';
import type PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

import { w, wx } from 'common/i18n/website-rendering';

function importPhotoSwipeModules(): Promise<{
    PhotoSwipe: typeof PhotoSwipe;
    PhotoSwipeUI_Default: typeof PhotoSwipeUI_Default;
}> {
    return Promise.all([
        import(
            /* webpackChunkName: "photoswipe", webpackPrefetch: true */ 'photoswipe'
        ),
        import(
            /* webpackChunkName: "photoswipe" */ 'photoswipe/dist/photoswipe-ui-default'
        ),
        // @ts-expect-error TypeScript doesn't understand dynamic .less imports
        import(/* webpackChunkName: "photoswipe" */ './photoswipe.less'),
    ]).then(([{ default: PhotoSwipe }, { default: PhotoSwipeUI_Default }]) => ({
        PhotoSwipe,
        PhotoSwipeUI_Default,
    }));
}

const html = `
<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="pswp__bg"></div>
    <div class="pswp__scroll-wrap">
        <div class="pswp__container">
            <div class="pswp__item"></div>
            <div class="pswp__item"></div>
            <div class="pswp__item"></div>
        </div>
        <div class="pswp__ui pswp__ui--hidden">
            <div class="pswp__top-bar">
                <div class="pswp__counter"></div>
                <button class="pswp__button pswp__button--close" title="${w(
                    'Close (Esc)'
                )}"></button>
                <button class="pswp__button pswp__button--share" title="${w(
                    'Share'
                )}"></button>
                <button class="pswp__button pswp__button--fs" title="${w(
                    'Toggle full screen'
                )}"></button>
                <button class="pswp__button pswp__button--zoom" title="${w(
                    'Zoom in/out'
                )}"></button>
                <div class="pswp__preloader">
                    <div class="pswp__preloader__icn">
                        <div class="pswp__preloader__cut">
                            <div class="pswp__preloader__donut"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                <div class="pswp__share-tooltip"></div>
            </div>
            <button class="pswp__button pswp__button--arrow--left" title="${w(
                'Previous (arrow left)'
            )}">
            </button>
            <button class="pswp__button pswp__button--arrow--right" title="${w(
                'Next (arrow right)'
            )}">
            </button>
            <div class="pswp__caption">
                <div class="pswp__caption__center"></div>
            </div>
        </div>
    </div>
</div>`;

let photoswipeElement: HTMLDivElement;

export async function open(
    items: PhotoSwipeUI_Default.Item[],
    options: PhotoSwipeUI_Default.Options = {}
): Promise<PhotoSwipe<PhotoSwipeUI_Default.Options>> {
    if (!photoswipeElement) {
        document.body.insertAdjacentHTML('beforeend', html);
        photoswipeElement = document.querySelector('.pswp')!;
    }

    const mergedOptions: PhotoSwipeUI_Default.Options = {
        // Disable history module
        history: false,

        bgOpacity: 0.7,

        errorMsg: `<div class="pswp__error-msg">${w(
            '<a href="%url%" target="_blank">The image</a> could not be loaded.'
        )}</div>`,
        shareButtons: [
            {
                id: 'facebook',
                label: `<span class="website-rendering-icon-facebook"></span> ${wx(
                    'share facebook',
                    'Share'
                )}`,
                url: 'https://www.facebook.com/sharer/sharer.php?u={{url}}',
            },
            {
                id: 'twitter',
                label: `<span class="website-rendering-icon-twitter"></span> ${wx(
                    'share twitter',
                    'Share'
                )}`,
                url: 'https://twitter.com/intent/tweet?text={{text}}&url={{url}}',
            },
            {
                id: 'pinterest',
                label: `<span class="website-rendering-icon-pinterest"></span> ${wx(
                    'share pinterest',
                    'Pin it'
                )}`,
                url: 'https://www.pinterest.com/pin/create/button/?url={{url}}&media={{image_url}}&description={{text}}',
            },
        ],

        ...options,
    };

    const { PhotoSwipe, PhotoSwipeUI_Default } =
        await importPhotoSwipeModules();

    const gallery = new PhotoSwipe(
        photoswipeElement,
        PhotoSwipeUI_Default,
        items,
        mergedOptions
    );
    gallery.init();
    return gallery;
}

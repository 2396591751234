import { intersectionBy } from 'lodash-es';

import type {
    DraftProductVariant,
    DraftProductVariantPropertyValue,
} from 'app/features/webshop/modals/product/draft-types';
import type { ProductVariantWithProperties } from 'app/features/webshop/types';

/**
 * Get the most appropriate ProductVariant for a combination of property values.
 *
 * TODO: The naming here can probably be better, but it's hard right now with
 *       the multiple things called 'variant' and stuff.
 */
export function getOrCreateVariantForCombination(
    variants: DraftProductVariant[],
    combination: DraftProductVariantPropertyValue[]
): DraftProductVariant {
    return (
        getVariantForCombination(variants, combination) ||
        getNewVariantForCombination(variants, combination)
    );
}

/**
 * Get an existing ProductVariant for a combination for property values.
 */
export function getVariantForCombination<
    Variant extends DraftProductVariant | ProductVariantWithProperties,
    VariantPropertyValue extends { id: number | string },
>(variants: Variant[], combination: VariantPropertyValue[]): Variant | null {
    return (
        variants.find((variant) =>
            // TODO: Remove explicit type arguments. I needed to add them
            //  because TypeScript tests were failing in some situations, for
            //  some weird reason.
            constainsAllValues<
                Variant['propertyValues'][number],
                VariantPropertyValue
            >(variant.propertyValues, combination)
        ) || null
    );
}

let variantRefCounter = 0;

export function getNewVariantForCombination(
    variants: DraftProductVariant[],
    combination: DraftProductVariantPropertyValue[]
): DraftProductVariant {
    return {
        label: '',
        price: null,
        stock: 0,
        limited: false,
        weight: 0,
        onSale: false,
        oldPrice: null,
        ...getClosestVariantForCombination(variants, combination),
        id: 'new' + variantRefCounter++,
        propertyValues: combination,
        subscribers: 0,
        isNew: true,
    };
}

/**
 * Find closest enabled variant for a combination of property values.
 */
function getClosestVariantForCombination(
    variants: DraftProductVariant[],
    combination: DraftProductVariantPropertyValue[]
): DraftProductVariant | undefined {
    const enabledVariants = variants.filter((variant) => !variant.disabled);

    // select variant that matches all values
    // (reverse list to prioritize last items in list)
    const result = enabledVariants
        .reverse()
        .find((variant) =>
            constainsAllValues(variant.propertyValues, combination)
        );

    // no variant found? Drop a property value to be less specific
    if (!result && combination.length > 0) {
        return getClosestVariantForCombination(
            variants,
            combination.slice(0, combination.length - 1)
        );
    }

    return result;
}

/**
 * Check if an array of variants contains all variants from another array.
 */
function constainsAllValues<
    A extends { id: number | string },
    B extends { id: number | string },
>(valuesA: A[], valuesB: B[]) {
    return getIntersection(valuesA, valuesB).length === valuesB.length;
}

function getIntersection<
    A extends { id: number | string },
    B extends { id: number | string },
>(valuesA: A[], valuesB: B[]) {
    return intersectionBy(valuesA, valuesB, (value) => value.id);
}

function frameIsEmpty(frame) {
    try {
        return (
            frame.contentWindow.document.querySelectorAll('body *').length === 0
        );
    } catch (e) {
        return false;
    }
}

function reloadFrame(frame) {
    // eslint-disable-next-line no-self-assign -- Required to reload
    frame.src = frame.src;
}

export default function watchFrame(frame) {
    const refreshFrame = setInterval(() => {
        if (frameIsEmpty(frame)) {
            reloadFrame(frame);
        } else {
            clearInterval(refreshFrame);
        }
    }, 2000);
}

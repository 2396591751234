import { postJSON } from 'common/http';

export interface Address {
    streetAddress: string;
    postalCode: string;
    city: string;
    country: string;
    countrySubdivision: string;
}

interface FormatAddressData {
    data: string;
}

const cachedAddressRequests: Partial<
    Record<string, Promise<FormatAddressData>>
> = {};

/**
 * Format an address according to the current locale and the address country.
 * Async because the backend does the actual formatting. We do this in the
 * backend because we also need to take the address country into account, and
 * also because this method translates country codes and country subdivision
 * codes to human readable names.
 */
export default async function formatAddress(
    domain: string,
    locale: string,
    address: Address
): Promise<string> {
    const cacheKey = getCacheKey(address, locale);

    let request = cachedAddressRequests[cacheKey];

    if (request === undefined) {
        const baseUrl = domain === 'editor' ? '/v2/api' : '/_api';
        const params = { address };

        request = postJSON<FormatAddressData>(
            `${baseUrl}/format-address/${locale}`,
            params
        );
        cachedAddressRequests[cacheKey] = request;
    }

    return (await request).data;
}

function getCacheKey(address: Address, locale: string) {
    return `
        ${locale}
        ${address.streetAddress}
        ${address.postalCode}
        ${address.city}
        ${address.country}
        ${address.countrySubdivision}
    `;
}

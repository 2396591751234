/**
 * Adds classes to an element such that CSS transitions can be fired.
 * The function mimics the behaviour of react-transition-group's CSSTransition.
 */
type Transitioner = (
    element: HTMLElement,
    className: string,
    timeout: number
) => Promise<void>;

/**
 * Creates a Transitioner that adds classes with the given type as postfix.
 */
function makeTransitioner(type: string): Transitioner {
    return (element, className, timeout = 500) =>
        new Promise((resolve) => {
            const baseClassName = [className, type].join('-');
            const activeClassName = `${baseClassName}-active`;
            const doneClassName = `${baseClassName}-done`;

            element.classList.add(baseClassName);
            element.offsetHeight; // force reflow
            element.classList.add(activeClassName);

            setTimeout(onTransitionEnd, timeout);

            function onTransitionEnd() {
                element.classList.remove(activeClassName);
                element.classList.add(doneClassName);

                resolve();
            }
        });
}

export const enterTransition = makeTransitioner('enter');
export const exitTransition = makeTransitioner('exit');

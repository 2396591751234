export default function formatNumber(
    locale: string,
    number: number,
    options?: Intl.NumberFormatOptions
): string {
    const numberFormat = new Intl.NumberFormat(locale, options);
    const formatted = numberFormat.format(number);

    if (locale === 'zu-ZA') {
        return `¡${formatted}¡`;
    }

    return formatted;
}

// Add support in IE for ChildNode.remove()
// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
(function(window) {
    var arr = [];

    if ('Element' in window) arr.push(Element.prototype);
    if ('CharacterData' in window) arr.push(CharacterData.prototype);
    if ('DocumentType' in window) arr.push(DocumentType.prototype);

    arr.forEach(function(item) {
        if (item.hasOwnProperty('remove')) {
            return;
        }
        Object.defineProperty(item, 'remove', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                if (this.parentNode !== null) this.parentNode.removeChild(this);
            },
        });
    });
})(self);

import { fetchJSON } from 'common/http';

export type CountryNamesData = Record<string, string>;

const cachedCountryRequests: Partial<
    Record<string, Promise<CountryNamesData>>
> = {};

export default function fetchCountryNames(
    domain: string,
    locale: string
): Promise<CountryNamesData> {
    let request = cachedCountryRequests[locale];
    if (request !== undefined) {
        return request;
    }

    const baseUrl = domain === 'editor' ? '/v2/api' : '/_api';
    request = fetchJSON<CountryNamesData>(`${baseUrl}/countries/1/${locale}`);
    cachedCountryRequests[locale] = request;
    return request;
}

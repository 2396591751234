import $ from 'jquery';
import { w } from 'common/i18n/website-rendering';
import jwGlobal from 'common/config';
import { getProductImageUrl } from 'website-rendering/webshop/selectors';

const template = ({
    title,
    details,
    image,
    addedQuantity,
    modalTitle,
    actionButtonText,
    actionButtonUrl,
}) => `
<div class="jw-popover-container jw-popover-container--modal">
    <div class="jw-popover-backdrop"></div>
    <div class="jw-popover" style="max-width: 30em;" tabindex="-1">
        <div class="jw-popover__content jw-section-white">
            <div class="product product--row">
                ${
                    image
                        ? `
                            <div class="product__image-container">
                                <img
                                    class="product__image"
                                    src="${image}"
                                    alt="${title}"
                                >
                            </div>
                        `
                        : ''
                }
                <div class="product__container">
                    <div class="product__price">${modalTitle}</div>
                    <div class="product__summary">
                        <h3 class="product__heading">
                            ${title}
                        </h3>
                        ${
                            addedQuantity
                                ? `<span class="product__heading-addition">
                            ${
                                addedQuantity > 1
                                    ? `<span class="product__quantity"> × ${addedQuantity}</span>`
                                    : ''
                            }
                        </span>`
                                : ''
                        }
                    </div>
                    ${
                        details
                            ? `<div class="product__price">${details}</div>`
                            : ''
                    }
                </div>
            </div>

            <div style="display: flex; align-items: baseline; justify-content: space-between;">
                <a href="javascript:void(0)" class="js-continue-shopping">
                    &laquo; ${w('Continue shopping')}
                </a>
                <a href="${actionButtonUrl}"
                   class="jw-btn jw-btn-sm jw-btn-roundness-default jw-btn-style-default jw-btn__default-color">
                    <span>${actionButtonText}</span>
                </a>
            </div>
        </div>
    </div>
</div>
`;

export default function addedToCartModal(cartProduct, addedQuantity) {
    const productVariant = cartProduct.productVariant;
    const product = productVariant.product;

    // Render
    const html = template({
        title: product.title,
        details: cartProduct.label || '',
        image: getProductImageUrl(cartProduct),
        addedQuantity: addedQuantity,
        modalTitle: w('Added to cart') + ':',
        actionButtonText: w('Go to cart'),
        actionButtonUrl: jwGlobal.websiteRendering.routes['checkout/cart'],
    });

    openModal(html);
}

export function addedToWishlistModal(product) {
    const html = template({
        title: product.title,
        image: getProductImageUrl({ productVariant: { product: product } }),
        modalTitle: w('Added to wishlist') + ':',
        actionButtonText: w('View wishlist'),
        actionButtonUrl: jwGlobal.websiteRendering.routes['wishlist'],
    });

    openModal(html);
}

function openModal(html) {
    // Mount
    const $element = $(html);
    const $body = $('.jw-section-content');
    $body.append($element);

    const unmount = () => $element.remove();

    // Show modal with an animation
    const toggleModal = (show) => {
        $element.toggleClass('is-hidden', !show);
    };

    const close = () => {
        toggleModal(false);
        setTimeout(() => unmount(), 200);
    };

    toggleModal(false);
    $element[0].offsetHeight;
    toggleModal(true);

    // Focus popover after animation is complete
    setTimeout(() => {
        $element.find('.jw-popover').focus();
    }, 200);

    // Bind events
    $element.find('.js-continue-shopping').on('click', (e) => {
        e.preventDefault();
        close();
    });

    $element.find('.jw-popover-backdrop').on('click', () => close());
}

/**
 * A promise-style check if document is ready.
 * Heavily inspired by jQuery.
 * @see https://github.com/jquery/jquery/blob/master/src/core/ready.js
 */
const readyPromise = new Promise<void>((resolve) => {
    // Catch cases where $(document).ready() is called
    // after the browser event has already occurred.
    // Support: IE <=9 - 10 only
    // Older IE sometimes signals "interactive" too soon
    if (document.readyState !== 'loading') {
        resolve();
    }

    function onLoad() {
        document.removeEventListener('DOMContentLoaded', onLoad);
        document.removeEventListener('load', onLoad);
        resolve();
    }

    document.addEventListener('DOMContentLoaded', onLoad);

    // A fallback to window.onload, that will always work
    window.addEventListener('load', onLoad);
});

export function domReady(): Promise<void> {
    return readyPromise;
}

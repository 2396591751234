import { fitAndPosition } from 'object-fit-math';
import { type FitMode, type Rect } from 'object-fit-math/dist/types';

/**
 * Get the bounds of the image as it is actually rendered, taking into account
 * the value of object-fit.
 *
 * Note that getBoundingClientRect() returns the rect of the <img> element, not
 * the rect of the actual rendered image.
 */
export default function getObjectFitClientRect(img: HTMLImageElement): Rect {
    const objectFit = (window
        .getComputedStyle(img)
        .getPropertyValue('object-fit') || 'fill') as FitMode;

    const { naturalWidth, naturalHeight } = img;
    const naturalSize = { width: naturalWidth, height: naturalHeight };

    const { left: x, top: y, width, height } = img.getBoundingClientRect();
    const boxSize = { width, height };

    const rect = fitAndPosition(boxSize, naturalSize, objectFit);

    return {
        x: x + rect.x,
        y: y + rect.y,
        width: rect.width,
        height: rect.height,
    };
}

/**
 * Breakpoint minimum width.
 */
export enum Breakpoint {
    XS = 480,
    SM = 768,
    MD = 992,
    LG = 1200,
    XL = 1560,
}

import $ from 'jquery';
import { debounce } from 'lodash-es';

// Note: these thresholds are also defined in the backend, in
//       \PageRendering\Model\RenderOptions::getResponsiveContainerClasses()
const WIDTH_THRESHOLDS = [200, 300, 400, 480, 540, 600, 640, 800];

export const DEBOUNCE_WAIT = 150;
export const DEBOUNCE_OPTIONS = {
    leading: true,
    trailing: true,
    maxWait: DEBOUNCE_WAIT,
};

export const responsiveContainerSelector = '.jw-responsive';

export function updateResponsiveContainer(el: HTMLElement): void {
    const width = el.offsetWidth;

    el.classList.add('jw-responsive');

    WIDTH_THRESHOLDS.forEach((threshold) => {
        el.classList.toggle(`lt${threshold}`, width <= threshold);
    });

    // Notify other code that the responsive container may have resized
    $(el).trigger('jw.responsive-container-resize');
}

export function updateAllResponsiveContainers(): void {
    const containers = document.querySelectorAll(responsiveContainerSelector);

    containers.forEach((container) => {
        updateResponsiveContainer(container as HTMLElement);
    });
}

export const updateAllResponsiveContainersDebounced = debounce(
    updateAllResponsiveContainers,
    DEBOUNCE_WAIT,
    DEBOUNCE_OPTIONS
);

export function initResponsiveContainers(): void {
    // Synchronously update responsive containers.
    // (note: this used to be executed with `requestAnimationFrame()`, butr this
    // mostly caused us to have 2 expensive frames (as browsers rerender between
    // animation frames)
    updateAllResponsiveContainers();

    // TODO implement using a ResizeObserver
    window.addEventListener('resize', () =>
        updateAllResponsiveContainersDebounced()
    );
}

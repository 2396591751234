import { fetchJSON, postJSON } from 'common/http';

export const initCart = () =>
    function (dispatch, getState) {
        const state = getState();

        if (state.cart && state.cart.data) {
            return Promise.resolve();
        }

        // load bootstrapped cart if available
        if (window.JOUWWEB.cart) {
            return Promise.resolve(
                dispatch(receiveCartData(window.JOUWWEB.cart))
            );
        }

        // not already loaded, not already bootstapped -- fetch cart from server
        return dispatch(fetchCartData());
    };

let cartPromise;
export const fetchCartData = () =>
    function (dispatch, getState) {
        const state = getState();
        if (state.cart.status === 'fetching') {
            return Promise.resolve(cartPromise);
        }

        dispatch({ type: 'FETCH_CART_DATA' });

        const requestNumber = ++cartUpdateRequestNumber;
        return fetchJSON(`/_api/webshop/cart`).then((response) =>
            dispatch(receiveCart(requestNumber, response))
        );
    };

/**
 * Used to make sure only the last cart status is processed. Increment before
 * sending a request that calls receiveCart() on response cart update.
 *
 * @type {number}
 */
let cartUpdateRequestNumber = 0;
const receiveCart = (requestNumber, response) =>
    function (dispatch) {
        const {
            data,
            shippingChoices,
            totalPriceIncludingTax,
            addedCartProductId,
            amountToMinimumOrderPrice,
        } = response;

        // Skip updates when this was not the last request
        if (requestNumber === cartUpdateRequestNumber) {
            dispatch(receiveCartData(data));
            dispatch(
                receiveCartMeta({
                    shippingChoices,
                    totalPriceIncludingTax,
                    amountToMinimumOrderPrice,
                })
            );
        }

        // Return added cart product for display in added to cart modal
        return Promise.resolve(
            data.products.find(
                (cartProduct) => cartProduct.id === addedCartProductId
            )
        );
    };

export const receiveCartData = (cartData) => ({
    type: 'RECEIVE_CART_DATA',
    cartData,
});

export const addProductOptionToCart = (productVariant, fields, quantity = 1) =>
    function (dispatch) {
        const requestNumber = ++cartUpdateRequestNumber;
        return postJSON(`/_api/webshop/cart/add`, {
            id: productVariant.id,
            fields,
            quantity,
        })
            .then((response) => dispatch(receiveCart(requestNumber, response)))
            .then((cartProduct) => {
                dispatch({
                    type: 'CART_ADD',
                    cartProduct,
                });

                return Promise.resolve(cartProduct);
            });
    };

export const updateQuantity = (cartProductId, quantity) =>
    function (dispatch) {
        dispatch({
            type: 'CART_UPDATE_QUANTITY',
            id: cartProductId,
            quantity,
        });

        const requestNumber = ++cartUpdateRequestNumber;
        return postJSON(`/_api/webshop/cart/update-quantity`, {
            id: cartProductId,
            quantity,
        }).then((response) => dispatch(receiveCart(requestNumber, response)));
    };

export const receiveCartMeta = ({
    shippingChoices,
    totalPriceIncludingTax,
    amountToMinimumOrderPrice,
}) => ({
    type: 'RECEIVE_CART_META',
    shippingChoices,
    totalPriceIncludingTax,
    amountToMinimumOrderPrice,
});

export const setCouponCode = (couponCode) =>
    function (dispatch) {
        dispatch({
            type: 'CART_SET_COUPON',
            couponCode,
        });

        const requestNumber = ++cartUpdateRequestNumber;
        return postJSON(`/_api/webshop/cart/set-coupon`, {
            couponCode,
        }).then((response) => {
            dispatch(receiveCart(requestNumber, response));
            return response;
        });
    };

export const estimateShipping = ({ shippingCountryCode, shippingChoiceCode }) =>
    function (dispatch) {
        dispatch({
            type: 'CART_SET_SHIPPING_COUNTRY_CODE',
            shippingCountryCode,
        });
        dispatch({
            type: 'CART_SET_SHIPPING_CHOICE_CODE',
            shippingChoiceCode,
        });

        const requestNumber = ++cartUpdateRequestNumber;
        return postJSON(`/_api/webshop/cart/estimate-shipping`, {
            shippingCountryCode,
            shippingChoiceCode,
        }).then((response) => dispatch(receiveCart(requestNumber, response)));
    };

import $ from 'jquery';
import { domReady } from 'common/dom';

domReady().then(() => {
    $('body').on('click', '.js-download-link', function () {
        var $element = $(this).closest('.jw-element');
        const elementId = $element.data('jw-element-id');

        window.downloadGaFunctions?.[elementId]?.();
    });
});

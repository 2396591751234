/**
 * 🎵 common inits 🎵 (this is a Dutch Eurovision Song Contest 2014 joke)
 *                    (jokes are always funny if they need to be explained)
 *
 * If some global initialization is necessary, put it here.
 */

import config from './config';

// the CSP nonce is not always accessible via the attribute, so we have to try fallbacks
const cspNonceScript =
    document.querySelector('script[nonce]') ||
    document.querySelector('script') ||
    document.currentScript;
if (cspNonceScript) {
    __webpack_nonce__ =
        (cspNonceScript as any).nonce || cspNonceScript.getAttribute('nonce');
}

/**
 * Make sure lazy-loaded modules are loaded from our CDN.
 * Our Webpack publicPath does not include a hostname so that the assets can
 * with the assetUrl() view helper. Unfortunately this also causes our lazy-
 * loaded modules to *not* be loaded from the CDN, because they will use the
 * publicPath from the Webpack config.
 *
 * TODO: we should probably just fix this in the Webpack config. This is risky
 * though -- the assetUrl() cache buster wouldn't apply anymore (not sure if that's
 * a problem, but still) -- so I don't want to try it now.
 */
declare let __webpack_public_path__: string;
if (__webpack_public_path__.startsWith('/assets')) {
    __webpack_public_path__ = `${config.application.assetsUrl}${__webpack_public_path__}`;
}

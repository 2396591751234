import $ from 'jquery';
import { domReady } from 'common/dom';

domReady().then(() => {
    const $item = $('.jw-menu-search-item');
    const $button = $item.children('.jw-menu-link');
    const $popoverContainer = $item.find('.jw-popover-container');
    const $popoverBackdrop = $item.find('.jw-popover-backdrop');
    const $input = $item.find('.jw-search__input');

    function togglePopover(state) {
        $popoverContainer.toggleClass('is-hidden', !state);
        if (state) {
            setTimeout(() => {
                $input[0].focus();
            }, 50);
        }
    }

    $button.on('click', (e) => {
        e.preventDefault();
        togglePopover(true);
    });

    $popoverBackdrop.on('click', () => {
        togglePopover(false);
    });
});

import $ from 'jquery';

/**
 * Fotorama keeps the cropping when showing an image in fullscreen. This is
 * usually very ugly for images that are cropped. This code will make sure the
 * images enter "contain" fit when they are shown in fullscreen mode.
 *
 * Based on https://github.com/artpolikarpov/fotorama/issues/64
 */
export default function fixFotoramaFullscreen() {
    document.querySelectorAll('.fotorama').forEach((element) => {
        if (element.dataset.fotoramaFullscreenGenerated) {
            return;
        }

        const $element = $(element);
        const fotorama = $element.data('fotorama');

        if (!fotorama) {
            return;
        }

        element.dataset.fotoramaFullscreenGenerated = 'true';

        const initialFit = fotorama.options.fit;

        $element.on('fotorama:fullscreenenter', (event, fotorama) => {
            fotorama.setOptions({
                fit: 'contain',
            });
        });
        $element.on('fotorama:fullscreenexit', (event, fotorama) => {
            fotorama.setOptions({
                fit: initialFit,
            });
        });
    });
}

interface TokenParams {
    token: string | null;
    timestamp: number;
    expires: string;
}

function writeCookie(value: string, expires: string) {
    document.cookie = `JwUnsafe=${encodeURIComponent(
        value
    )}; expires=${expires}`;
}

function authorized() {
    if (window.Unsafe?.render) {
        window.Unsafe.render();
        return;
    }

    setTimeout(authorized, 100);
    return;
}

window.Unsafe = {
    ...(window.Unsafe || {}),

    authorized,

    /** Called via JSONP by `UnsafeController::tokenAction()` */
    authorizeToken: (params: TokenParams) => {
        if (params.token) {
            writeCookie(`1|${params.token}`, params.expires);
            authorized();
        } else {
            writeCookie(`0|${params.timestamp}`, params.expires);
        }
    },
};

/**
 * Most of the methods in this file are reselect-compatible selectors.
 * We don't use it yet (bigger bundle size for hardly any benefit), but if we start using it
 * in website-rendering we might also consider using it here.
 */

/**
 * @returns {boolean} Whether all products of the given option are already added to the cart.
 */
export function allProductsInCart(state, productVariantId) {
    const cartProduct = state.cart.data.products.find(
        (cartProduct2) => cartProduct2.productVariant.id === productVariantId
    );

    if (!cartProduct) {
        return false;
    }

    if (!cartProduct.productVariant.limited) {
        return false;
    }

    return cartProduct.quantity >= cartProduct.productVariant.stock;
}

/**
 * Returns the quantity of the product already added to the cart.
 *
 * @param {Object} state
 * @param {Number} productVariantId
 * @return {Number}
 */
export function getCartProductQuantity(state, productVariantId) {
    if (!isCartInitialized(state)) {
        return 0;
    }

    return state.cart.data.products.reduce((carry, cartProduct) => {
        return cartProduct.productVariant.id === productVariantId
            ? carry + cartProduct.quantity
            : carry;
    }, 0);
}

/**
 * @returns {Number} The total number of products in the cart.
 */
export function getCartProductCount(state) {
    return state.cart.data.products.reduce(function (accumulated, current) {
        return accumulated + current.quantity;
    }, 0);
}

export function getProductImageUrl(cartProduct) {
    const productVariant = cartProduct.productVariant;
    return (
        (productVariant.image || productVariant.product.images[0])?.url || null
    );
}

export function isCartInitialized(state) {
    return Boolean(state.cart && state.cart.data);
}

import { postFormData } from 'common/http';

import {
    getPageCurrent,
    initPagination,
} from 'website-rendering/helpers/pagination';
import { replaceElementParams } from 'website-rendering/helpers/element-params';
import replaceWith from 'website-rendering/helpers/replaceWith';
import scrollToElementTop from 'website-rendering/helpers/scollToElementTop';
import createProductContainers from 'website-rendering/webshop/helpers/createProductContainers';

import ProductGallerySorting from './ProductGallerySorting';
import ProductFilter from './ProductFilter';

export default class ProductGallery {
    /** @type {HTMLElement} */
    #element;

    /** @type {String|null} */
    #currentSorting = null;

    /** @type {Number|null} */
    #currentPage = null;

    /** @type {Record<string, string[]>} */
    #currentFilter = {};

    /** @type {Pagination} */
    #pagination;

    /**
     * @param {HTMLElement} element
     */
    constructor(element) {
        this.bindElement(element);
    }

    /**
     * Binds or re-binds to the various elements in the product-gallery HTML.
     * @param {HTMLElement} element - The new element to bind to.
     */
    bindElement(element) {
        this.#element = element;
        this.bindPagination(element.querySelector('.jw-pagination'));
        this.bindSorting(element.querySelector('.js-product-gallery-sorting'));
        this.bindFilters(
            element.querySelectorAll('.js-product-gallery-filter')
        );
        createProductContainers(element);
    }

    /**
     * Binds (or re-binds, if previously bound) to the pagination element.
     * @param {HTMLElement|null} paginationElement
     */
    bindPagination(paginationElement) {
        if (!paginationElement) {
            return;
        }

        if (this.#pagination) {
            // Re-bind the pagination to the new element
            this.#pagination.replaceElement(paginationElement);
        } else {
            // Initialize the pagination element
            this.#pagination = initPagination(paginationElement, {
                onPaginate: async (page) => {
                    this.#currentPage = page - 1;
                    await this.update();
                },
            });
        }

        this.#currentPage = getPageCurrent(paginationElement) - 1;
    }

    /**
     * @param {HTMLElement|null} sortingElement
     */
    bindSorting(sortingElement) {
        if (!sortingElement) {
            return;
        }

        const productGallerySorting = new ProductGallerySorting(
            sortingElement,
            {
                onSort: (sorting) => {
                    this.#currentSorting = sorting;
                    // Always reset page when changing order
                    this.#currentPage = 0;
                    this.update();
                },
            }
        );

        this.#currentSorting = productGallerySorting.getCurrentSorting();
    }

    /**
     * @param {NodeListOf<HTMLElement>} elements
     */
    bindFilters(elements) {
        elements.forEach((element) => {
            const productFilter = new ProductFilter(element, {
                onFilter: (name, checkedOptions) => {
                    this.#currentFilter[name] = checkedOptions;
                    // Always reset page when changing filter
                    this.#currentPage = 0;
                    this.update();
                },
            });

            this.#currentFilter[productFilter.getName()] =
                productFilter.getCheckedOptions();
        });
    }

    /**
     * Updates the product list based on the selected page, sorting, and filter.
     *
     * @returns {Promise<void>}
     */
    async update() {
        const element = this.#element;
        element.classList.add('jw-element-is-loading');

        // Scroll to the top of the product list
        scrollToElementTop(element);

        try {
            const elementParams = {
                page: this.#currentPage,
                sort: this.#currentSorting,
                filter: this.#currentFilter,
            };

            // Update location with new parameters
            replaceElementParams(element.dataset.jwElementId, elementParams);

            // Fetch HTML for new parameters
            const response = await postFormData(window.location.href, {
                ep: {
                    [element.dataset.jwElementId]: elementParams,
                },
            });

            const newElement = replaceWith(element, response);

            // Re-bind to the elements, since the HTML was overwritten
            this.bindElement(newElement);
        } finally {
            element.classList.remove('jw-element-is-loading');
        }
    }
}

import { w } from 'common/i18n/website-rendering';

/**
 * Social network type {@see \Page\Model\MobileBarSettings::SOCIAL_NETWORKS}
 */
type SocialNetworkType =
    | 'facebook'
    | 'twitter'
    | 'pinterest'
    | 'instagram'
    | 'youtube'
    | 'tumblr'
    | 'linkedin'
    | 'tiktok'
    | 'telegram'
    | 'discord';

/**
 * Gets the URL for the given social network + handle.
 *
 * @param network - Social network type.
 * @param handle - Social network handle.
 */
export function getSocialNetworkUrl(
    network: SocialNetworkType,
    handle: string
): string {
    switch (network) {
        case 'facebook':
            return `https://facebook.com/${handle}`;
        case 'twitter':
            return `https://twitter.com/${handle}`;
        case 'pinterest':
            return `https://pinterest.com/${handle}`;
        case 'instagram':
            return `https://instagram.com/${handle}`;
        case 'youtube':
            return `https://youtube.com/${handle}`;
        case 'tumblr':
            return `https://${handle}.tumblr.com/`;
        case 'linkedin':
            return `https://linkedin.com/${handle}`;
        case 'tiktok':
            return `https://tiktok.com/@${handle}`;
        case 'telegram':
            return `https://t.me/${handle}`;
        case 'discord':
            return `https://discord.gg/${handle}`;
        default:
            throw new Error(`Unknown social network "${network}"`);
    }
}

/**
 * Gets the name of the given social network.
 *
 * @param network - Social network type.
 */
export function getSocialNetworkTitle(network: SocialNetworkType): string {
    switch (network) {
        case 'facebook':
            return w('Facebook');
        case 'twitter':
            return w('X');
        case 'pinterest':
            return w('Pinterest');
        case 'instagram':
            return w('Instagram');
        case 'youtube':
            return w('YouTube');
        case 'tumblr':
            return w('Tumblr');
        case 'linkedin':
            return w('LinkedIn');
        case 'tiktok':
            return w('TikTok');
        case 'telegram':
            return w('Telegram');
        case 'discord':
            return w('Discord');
        default:
            throw new Error(`Unknown social network "${network}"`);
    }
}

import type { Money, MoneyDecimal } from 'common/money/types';

import formatNumber from './formatNumber';

export interface CurrencyMethods {
    formatCurrency(
        value: MoneyDecimal<number>,
        currency?: string,
        locale?: string,
        hideZeroDecimals?: boolean
    ): string;
    /**
     * Formats currency without the currency sign for use in inputs. Note: When
     * a string is passed it has to be a simple decimal-number string (generated
     * in PHP with `formatDecimalMoney()`. It won't be parsed as if user-input
     * like with `parseNumber()`.
     */
    formatCurrencyNumber(
        value: MoneyDecimal<string | number>,
        locale?: string
    ): string;
    formatMoney(
        value: Money,
        locale?: string,
        hideZeroDecimals?: boolean
    ): string;
    getCurrencySymbol(currency?: string): string;
}

// See https://www.xe.com/symbols.php for additional symbols
const symbols: Record<string, string> = {
    CRC: '₡',
    EUR: '€',
    GBP: '£',
    ILS: '₪',
    INR: '₹',
    JPY: '¥',
    KRW: '₩',
    NGN: '₦',
    PHP: '₱',
    PLN: 'zł',
    PYG: '₲',
    RUB: '₽',
    THB: '฿',
    UAH: '₴',
    USD: '$',
    VND: '₫',
};

export default function createCurrencyMethods(
    fallbackCurrency: string,
    fallbackLocale: string
): CurrencyMethods {
    const formatCurrency = createCurrencyFormatter(
        fallbackCurrency,
        fallbackLocale
    );

    function formatMoney(
        money: Money,
        locale?: string,
        hideZeroDecimals = false
    ): string {
        const { amount, currency } = money;
        return formatCurrency(
            (Number(amount) / 100) as MoneyDecimal<number>,
            currency,
            locale,
            hideZeroDecimals
        );
    }

    function formatCurrencyNumber(
        value: MoneyDecimal<number | string>,
        locale?: string
    ): string {
        const number = typeof value === 'string' ? Number(value) : value;
        if (!isFinite(number)) {
            throw new Error('Invalid number passed to formatCurrencyNumber().');
        }

        return formatNumber(locale ?? fallbackLocale, number, {
            // Note: We can't just hide the currency symbol from
            // `formatCurrency()` without dirty hacks but have no way to know
            // `minimumFractionDigits` without using it. Defaulting to 2
            // decimals when there are any seems like the best alternative. It
            // doesn't hide any decimals when the currency has more decimals.
            minimumFractionDigits: number % 1 === 0 ? 0 : 2,
            useGrouping: false,
        });
    }

    function getCurrencySymbol(currency?: string): string {
        currency = currency ?? fallbackCurrency;
        return symbols[currency] ?? currency;
    }

    return {
        formatCurrency,
        formatCurrencyNumber,
        formatMoney,
        getCurrencySymbol,
    };
}

export function createCurrencyFormatter(
    fallbackCurrency: string,
    fallbackLocale: string
) {
    return function formatCurrency(
        value: MoneyDecimal<number>,
        currency?: string,
        locale?: string,
        hideZeroDecimals = false
    ): string {
        if (value < 0) {
            throw new Error(
                'Currency format of negative money is not allowed. For deductions prefix with - manually.'
            );
        }

        currency = currency ?? fallbackCurrency;
        locale = locale ?? fallbackLocale;

        // We disagree with the default Flemish money style and default to Dutch formatting.
        if (locale === 'nl-BE') {
            locale = 'nl-NL';
        }

        const options: Intl.NumberFormatOptions = {
            style: 'currency',
            currency,
        };
        if (hideZeroDecimals && value % 1 === 0) {
            options.minimumFractionDigits = 0;
        }

        return formatNumber(locale, value, options);
    };
}

/**
 * Scroll the page so element is aligned to the top of the viewport.
 */
export default function scrollToElementTop(element: HTMLElement): void {
    // Detect for smooth scroll support
    // https://stackoverflow.com/questions/46919627/is-it-possible-to-test-for-scrollintoview-browser-compatibility#answer-51070207
    if ('scrollBehavior' in document.documentElement.style) {
        // Only scroll if the top of the element isn't in view already.
        if (element.getBoundingClientRect().top < 0) {
            element.scrollIntoView({
                block: 'start',
                inline: 'nearest',
                behavior: 'smooth',
            });
        }
    } else {
        // Fallback for browsers with only support for the `alignToTop`
        // boolean parameter
        element.scrollIntoView(true);
    }
}

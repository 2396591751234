import $ from 'jquery';
import { registerScrollEffect } from 'website-rendering/scroll-effects';

let slideshow = null;
let slides = null;

export function setSlides() {
    slideshow = document.getElementById('jw-slideshow');
    slides = document.querySelectorAll('.jw-slideshow-slide-content');
}

function isParallaxEnabled() {
    return $('#jw-slideshow').hasClass('jw-slideshow--parallax');
}

/**
 * Parallax scrolling of banner image.
 * @param {HTMLElement} slide
 * @param {{ slideshowY: number, slideshowHeight: number, scrollY: number }} dimensions
 */
function updateParallax(slide, { slideshowY, slideshowHeight, scrollY }) {
    const backgroundPositionX = parseFloat(
        slide.getAttribute('data-background-position-x')
    );
    const backgroundPositionY = parseFloat(
        slide.getAttribute('data-background-position-y')
    );
    const speed = 0.5; // 0 = scrolls with page -- 1 = fixed to viewport

    // Slide offset is capped on two ends. First so it doesn't update when outside of the view.
    // Second so it only starts updating once the top of the slide reaches the top of the view.
    const slideTop = Math.max(slideshowY - scrollY, -slideshowHeight);
    const offset = (Math.max(slideTop, 0) - slideTop) * speed;

    const cssOffset = `calc(${backgroundPositionY * 100}% + ${offset}px)`;

    /* eslint-disable no-param-reassign */
    // By using CSS calc() we can leverage percentage background positioning with pixel parallax.
    slide.style.backgroundPosition = `
        ${backgroundPositionX * 100}%
        ${cssOffset}
    `;
    slide.style.backgroundAttachment = 'scroll';
    /* eslint-enable no-param-reassign */

    const video = slide.querySelector('.jw-slideshow-slide-video');
    if (video) {
        video.style.left = `${backgroundPositionX * 100}%`;
        video.style.top = cssOffset;
        video.style.transform = `translate(-${
            backgroundPositionX * 100
        }%, -${cssOffset})`;
    }
}

function isCaptionFadeoutEnabled() {
    const sliderIsFullWidth =
        slideshow?.offsetWidth === document.body.offsetWidth;

    return sliderIsFullWidth;
}

/**
 * Fade out the caption while scrolling.
 * @param {HTMLElement} slide
 * @param {{ slideshowY: number, slideshowHeight: number, scrollY: number }} dimensions
 */
function updateCaptionFadeout(slide, { slideshowY, slideshowHeight, scrollY }) {
    // 0 = banner is at top of the viewport -- 1 = fully above viewport
    const interp =
        Math.min(Math.max(scrollY - slideshowY, 0), slideshowHeight) /
        slideshowHeight;
    // Opacity will be 0 when scrolled 33% of the way
    const opacity = 1 - Math.min(interp * 3, 1);
    // eslint-disable-next-line no-param-reassign
    slide.querySelector('.bx-caption-wrapper').style.opacity = opacity;
}

/**
 * Update the scroll effects.
 */
function updateEffects({ scrollY }) {
    if (!slideshow) {
        return;
    }

    const slideshowY = slideshow.offsetTop;
    const slideshowHeight = slideshow.getBoundingClientRect().height;

    const parallaxEnabled = isParallaxEnabled();
    const captionFadeoutEnabled = isCaptionFadeoutEnabled();

    for (let i = 0; i < slides.length; i += 1) {
        const slide = slides[i];

        const dimensions = { slideshowY, slideshowHeight, scrollY };

        if (parallaxEnabled) updateParallax(slide, dimensions);
        if (captionFadeoutEnabled) updateCaptionFadeout(slide, dimensions);
    }
}

registerScrollEffect(updateEffects);

import { debounce } from 'lodash-es';

import { domReady } from 'common/dom';

import { getParentFrameService } from 'app/helpers/frame-service/parentFrameService';

const effects = [];

export function registerScrollEffect(effect) {
    effects.push(effect);
}

let updateEffectsHandle;

const isBackend = () => document.body.classList.contains('jw-is-backend');

export function getScrollDimensions() {
    if (isBackend()) {
        const scrollContainer =
            getParentFrameService().getFrameScrollContainer();
        return {
            scrollY: scrollContainer.scrollY,
            scrollHeight: scrollContainer.scrollHeight,
            frameHeight: scrollContainer.frameHeight,
        };
    }

    return {
        // Use pageYOffset instead of scrollY for better compatibility
        scrollY: window.pageYOffset,
        scrollHeight: window.document.documentElement.scrollHeight,
        frameHeight: window.innerHeight,
    };
}

export function scrollBy(x, y) {
    if (isBackend()) {
        const scrollContainer =
            getParentFrameService().getFrameScrollContainer();
        scrollContainer.scrollBy(x, y);
        return;
    }
    window.scrollBy(x, y);
}

/**
 * Update the scroll effects.
 * @param {boolean} [scrollStopped=false]
 */
export function updateEffects(scrollStopped = false) {
    if (effects.length === 0) {
        return;
    }

    const dimensions = getScrollDimensions();
    const params = {
        ...dimensions,
        scrollStopped,
    };

    effects.forEach((effect) => {
        effect(params);
    });
}

/**
 * Call updateEffects() in an animation frame.
 * @param {boolean} [scrollStopped=false]
 */
function requestUpdateEffects(scrollStopped = false) {
    window.cancelAnimationFrame(updateEffectsHandle);
    updateEffectsHandle = window.requestAnimationFrame(() =>
        updateEffects(scrollStopped)
    );
}

function handleScroll() {
    detectScrollStop();
    requestUpdateEffects();
}

const detectScrollStop = debounce(() => {
    requestUpdateEffects(true);
}, 300);

export function bindScrolling() {
    if (isBackend()) {
        const scrollContainer =
            getParentFrameService().getFrameScrollContainer();
        scrollContainer.addScrollListener(handleScroll);
    } else {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
    }
}

domReady().then(() => {
    updateEffects();
    bindScrolling();
});

import { fetchJSON } from 'common/http';

export type CountrySubdivisionsData = Record<string, string>;

// Backend: CountrySubdivisionService::requiresSubdivision()
export function isCountrySubdivisionRequired(
    countryCode: string | null
): boolean {
    return countryCode ? ['US', 'CA', 'IT'].includes(countryCode) : false;
}

const cachedRequests: Record<string, Promise<CountrySubdivisionsData>> = {};

export function fetchCountrySubdivisions(
    domain: string,
    locale: string,
    countryCode: string
): Promise<CountrySubdivisionsData> {
    if (!isCountrySubdivisionRequired(countryCode)) {
        throw new Error(
            `Trying to request subdivisions of country without subdivisions (${countryCode}).`
        );
    }

    // TODO also support the non-editor route in the backend
    const baseUrl = domain === 'editor' ? '/v2/api' : '/_api';
    const cacheKey = `${countryCode}/${locale}`;

    if (!cachedRequests[cacheKey]) {
        cachedRequests[cacheKey] = fetchJSON<CountrySubdivisionsData>(
            `${baseUrl}/country-subdivisions/1/${countryCode}/${locale}`
        );
    }

    return cachedRequests[cacheKey];
}

import formatNumber from './formatNumber';

export default function formatPercentage(
    locale: string,
    number: number,
    options: Intl.NumberFormatOptions | null = null
): string {
    return formatNumber(locale, number, {
        style: 'percent',
        maximumFractionDigits: 3,
        ...options,
    });
}

/**
 * Normalize a phone number for use in a WhatsApp URL.
 * @see getWhatsappUrl()
 */
export function normalizeWhatsappNumber(phoneNumber: string): string {
    // > "Omit any brackets, dashes, plus signs, and leading zeros when adding
    // > the phone number in international format."
    //
    // https://faq.whatsapp.com/563219570998715/?locale=en_US
    //
    // note: this is the exact same logic as in SocialFollowSettings.php
    return phoneNumber.replace(/(^0+)|([ \-+()])/g, '');
}

/**
 * Generate a link to a WhatsApp account.
 * @link https://faq.whatsapp.com/563219570998715/?locale=en_US
 */
export function getWhatsappUrl(phoneNumber: string): string {
    return `https://api.whatsapp.com/send?phone=${encodeURIComponent(
        phoneNumber
    )}`;
}

import $ from 'jquery';

import { domReady } from 'common/dom';
import {
    rejectComment,
    approveComment,
    reactComment,
    openNextPage,
    initializeCommentForm,
} from './frontend';
import { w } from 'common/i18n/website-rendering';

domReady().then(() => {
    const $commentModule = $('.jw-comment-module');

    // Fill in captcha fields for all new comment forms
    $commentModule.find('form').each(function () {
        initializeCommentForm($(this).closest('.jw-comment-module'), $(this));
    });

    $commentModule.on('click', '.jw-comment-delete', function () {
        if (
            !window.confirm(w('Are you sure you want to remove this comment?'))
        ) {
            return false;
        }

        rejectComment($(this).closest('.jw-comment'));
        return false;
    });

    $commentModule.on('click', '.jw-comment-accept', function () {
        approveComment($(this).closest('.jw-comment'));
        return false;
    });

    $commentModule.on('click', '.jw-comment-react', function () {
        reactComment($(this).closest('.jw-comment-react'));
        return false;
    });

    $('.jw-comments-pagination a').click(function () {
        openNextPage($(this).closest('.jw-element'));
        return false;
    });
});

// Add support in IE for `NodeList.forEach()`
// https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
(function(window) {
    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function(callback, thisArg) {
            thisArg = thisArg || window;
            for (var i = 0; i < this.length; i++) {
                callback.call(thisArg, this[i], i, this);
            }
        };
    }
})(self);

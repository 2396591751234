import { w } from 'common/i18n/website-rendering';
import { createFragment } from 'common/dom';
import config from 'common/config';

export interface Unsafe {
    render?: () => void;
    authorized?: () => void;
    authorizeToken?: (params: TokenParams) => void;
}

function getLinkToEditor() {
    const backend = window.JOUWWEB.application.backendDomain;
    const scheme = window.JOUWWEB.application.noSsl ? 'http' : 'https';
    const segmentId = document.body.dataset.jouwwebSegmentId;
    const segmentType = document.body.dataset.jouwwebSegmentType;
    const websiteId = window.JOUWWEB.website.id;

    let link = `${scheme}://${backend}/v2/website/${websiteId}/editor`;

    if (segmentType !== 'custom') {
        link = `${link}/${segmentType}/${segmentId}`;
    }

    link = `${link}?utm_source=(direct)&utm_medium=(none)&utm_campaign=(not set)`;

    return link;
}

window.Unsafe = {
    ...(window.Unsafe || {}),

    render: () => {
        document.body.classList.add('jw-is-website-button');

        // Render spacer
        const spacer = createFragment(
            '<div class="jw-website-spacer jw-website-spacer--jump-to-editor"></div>'
        );
        document.querySelector('.jw-bottom-bar__spacer')?.appendChild(spacer);

        let barText = w('You are logged in');
        if (config.website?.isOffline === true) {
            barText +=
                '. ' +
                w(
                    'You are looking at a preview of your website as a contributor.'
                );
        }

        // Render button
        const button = createFragment(`
            <div id="jw-jump-to-editor" class="jw-website-button">
                <span>${barText} </span>
                <a id="jw-jump-to-editor-link" rel="nofollow" href="${getLinkToEditor()}">
                    ${w('Edit this page')} &raquo;
                </a>
            </div>
        `);
        document
            .querySelector('.jw-bottom-bar__container')
            ?.appendChild(button);
    },
};

import ProductFilter from 'website-rendering/elements/product-gallery/ProductFilter';
import qs from 'qs';

export default class ProductFilterManager {
    /** @type {Record<string, string[]>} */
    #currentFilter = {};

    /**
     * @param {HTMLElement} element
     */
    constructor(element) {
        const filters = element.querySelectorAll('.js-search-product-filter');

        filters.forEach((element) => {
            const productFilter = new ProductFilter(element, {
                onFilter: (name, checkedOptions) => {
                    this.#currentFilter[name] = checkedOptions;
                    this.update();
                },
            });

            this.#currentFilter[productFilter.getName()] =
                productFilter.getCheckedOptions();
        });
    }

    update() {
        const current = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
            parseArrays: false,
        });
        delete current.filter;
        const filter = qs.stringify({
            ...current,
            filter: this.#currentFilter,
        });
        window.location.href = window.location.pathname + '?' + filter;
    }
}

import { combineReducers } from 'redux';

function data(state = null, action) {
    if (action.type === 'RECEIVE_CART_DATA') {
        return action.cartData;
    }

    return state;
}

function shippingChoices(state = null, action) {
    switch (action.type) {
        case 'RECEIVE_CART_DATA':
            return null;
        case 'RECEIVE_CART_META':
            return action.shippingChoices;
        default:
            return state;
    }
}

function totalPriceIncludingTax(state = null, action) {
    switch (action.type) {
        case 'RECEIVE_CART_DATA':
            return null;
        case 'RECEIVE_CART_META':
            return action.totalPriceIncludingTax;
        default:
            return state;
    }
}

function status(state = 'pending', action) {
    switch (action.type) {
        case 'FETCH_CART_DATA':
            return 'fetching';
        case 'RECEIVE_CART_META':
            return 'ok';
        default:
            return state;
    }
}

function amountToMinimumOrderPrice(state = null, action) {
    if (action.type === 'RECEIVE_CART_META') {
        return action.amountToMinimumOrderPrice;
    }

    return state;
}

export default combineReducers({
    data,
    shippingChoices,
    totalPriceIncludingTax,
    status,
    amountToMinimumOrderPrice,
});

export const ACCESS_TOKEN =
    'pk.eyJ1Ijoiam91d3dlYiIsImEiOiJja3J3MjVqYTEwY2czMnZyeHdkcDgydmZhIn0.PKf6LJG4nFO9PTV6Znx6Vg';

function getThemeColors(theme) {
    switch (theme) {
        case 'dark':
            return { foreColor: '#ffffff', middleColor: '#555' };
        case 'leshine':
            return { foreColor: '#337ac7', middleColor: '#ffffff' };
        case 'labelmaker':
            return { foreColor: '#fe0605', middleColor: '#ffffff' };
        case 'cali':
            return { foreColor: '#4f7b96', middleColor: '#fff' };
        default:
            return { foreColor: '#df4348', middleColor: '#fff' };
    }
}

export function isLanguageSupported(language) {
    // Copied from https://github.com/mapbox/mapbox-gl-language/blob/master/index.js
    return (
        [
            'ar',
            'en',
            'es',
            'fr',
            'de',
            'ja',
            'ko',
            'mul',
            'pt',
            'ru',
            'zh',
        ].indexOf(language.toLowerCase()) > -1
    );
}

export function createMarkerNodeFor(theme) {
    const { foreColor, middleColor } = getThemeColors(theme);

    let element = document.createElement('div');
    element.innerHTML = `
<svg width="27px" height="41px" enable-background="new 0 0 328.911 328.911" version="1.1" viewBox="0 0 328.911 328.911" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
    <metadata>
        <rdf:RDF>
            <cc:Work rdf:about="">
                <dc:format>image/svg+xml</dc:format>
                <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                <dc:title />
            </cc:Work>
        </rdf:RDF>
    </metadata>
    <g transform="matrix(12.182 0 0 12.471 0 -85.273)">
        <g transform="translate(3,29)">
            <ellipse cx="10.5" cy="5.8003" rx="10.5" ry="5.25" opacity=".04" />
            <ellipse cx="10.5" cy="5.8003" rx="10.5" ry="5.25" opacity=".04" />
            <ellipse cx="10.5" cy="5.8003" rx="9.5" ry="4.7727" opacity=".04" />
            <ellipse cx="10.5" cy="5.8003" rx="8.5" ry="4.2955" opacity=".04" />
            <ellipse cx="10.5" cy="5.8003" rx="7.5" ry="3.8182" opacity=".04" />
            <ellipse cx="10.5" cy="5.8003" rx="6.5" ry="3.3409" opacity=".04" />
            <ellipse cx="10.5" cy="5.8003" rx="5.5" ry="2.8637" opacity=".04" />
            <ellipse cx="10.5" cy="5.8003" rx="4.5" ry="2.3864" opacity=".04" />
        </g>
        <g class="jw-marker__back" fill="${foreColor}">
            <path d="m27 13.5c0 5.5746-6.75 13.5-12.25 21-0.73334 1-1.7667 1-2.5 0-5.5-7.5-12.25-15.277-12.25-21 0-7.4558 6.0442-13.5 13.5-13.5 7.4558 0 13.5 6.0442 13.5 13.5z" />
        </g>
        <g opacity=".25">
            <path d="m13.5 0c-7.4558 0-13.5 6.0442-13.5 13.5 0 5.7226 6.75 13.5 12.25 21 0.75 1.0227 1.7667 1 2.5 0 5.5-7.5 12.25-15.425 12.25-21 0-7.4558-6.0442-13.5-13.5-13.5zm0 1c6.9154 0 12.5 5.5846 12.5 12.5 0 2.3987-1.5044 5.6814-3.7793 9.2383-2.2749 3.5569-5.5156 7.4039-8.2773 11.17-0.19991 0.27261-0.33064 0.41454-0.44336 0.5332-0.11272-0.11867-0.24344-0.26059-0.44336-0.5332-2.7722-3.7802-5.6418-7.594-8.041-11.135-2.3992-3.5407-4.0156-6.8199-4.0156-9.2734 0-6.9154 5.5846-12.5 12.5-12.5z" />
        </g>
        <g transform="translate(8,8)">
            <circle cx="5.5" cy="5.5" r="5.5" class="jw-marker__circle" fill="${middleColor}" />
        </g>
    </g>
</svg>`;

    element.className = 'marker';
    element.style.width = '27px';
    element.style.height = '41px';
    return element;
}

export function convertMapboxBounds(mapBoxBounds) {
    return {
        swLat: mapBoxBounds.getSouthWest().lat,
        swLng: mapBoxBounds.getSouthWest().lng,
        neLat: mapBoxBounds.getNorthEast().lat,
        neLng: mapBoxBounds.getNorthEast().lng,
    };
}

export function getMapboxStyleFor(theme) {
    switch (theme) {
        case 'satellite':
            return 'mapbox://styles/jouwweb/cjhzxw9za1xmn2st9o8bz5esb';
        case 'dark':
            return 'mapbox://styles/jouwweb/cjhzxue0o4pys2sns2biifu8e';
        case 'night':
            return 'mapbox://styles/jouwweb/cjhzxu0ks3gd52spj19h36lm1';
        case 'silver':
            return 'mapbox://styles/jouwweb/cjhzxtpgn3ghm2sm5n78tn65l';
        case 'normal':
            return 'mapbox://styles/jouwweb/cjhzxuq513oam2st2eo2hl7jk';
        case 'leshine':
            return 'mapbox://styles/jouwweb/cjlcbt3t568s62spg7z05fg5w';
        case 'cali':
            return 'mapbox://styles/jouwweb/cjlcc9rpa697x2sny1h5c07a6';
        case 'labelmaker':
            return 'mapbox://styles/jouwweb/cjlccctpd69c42sm2vs6zifj1';
        case 'decimal':
            return 'mapbox://styles/jouwweb/cjlcczo3927qw2row7wmfpa5k';
        case 'scenic':
            return 'mapbox://styles/jouwweb/cjlcci9xy255d2srq3sve83my';

        default:
            throw 'Could not get a style given the input.';
    }
}

import $ from 'jquery';

import { domReady } from 'common/dom';
import { w, sprintf } from 'common/i18n/website-rendering';

import { renderCaptchaOnInteraction } from 'website-rendering/helpers/captcha';
import { setButtonLoading } from 'website-rendering/helpers/loading';

let Pikaday = null;
let moment = null;

function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

var formValues = function ($form) {
    var paramObj = {};
    $.each($form.serializeArray(), function (_, kv) {
        // Correctly transform array fields
        if (endsWith(kv.name, '[]')) {
            kv.name = kv.name.substring(0, kv.name.length - 2);
            paramObj[kv.name] = paramObj[kv.name] || [];
            paramObj[kv.name].push(kv.value);
        } else {
            paramObj[kv.name] = kv.value;
        }
    });
    return paramObj;
};

function errorMessageFromStatus(status) {
    switch (status) {
        case 413:
            return sprintf(w('File exceeds the maximum file size (%dMB).'), 10);
        default:
            return w('An unknown error occurred.');
    }
}

async function initializeContactForm($element) {
    const $form = $element.find('form');
    // Is the case when successful form submission

    if ($form.length === 0) {
        return;
    }

    renderCaptchaOnInteraction($form);

    // Initialize our own captcha logic
    $element
        .find('[name=captcha]')
        .prop('value', $element.data('jw-element-id'));

    // Initialize date picker fields
    const $datePickers = $element.find('[data-date-picker="true"]');

    if ($datePickers.length > 0) {
        const { Pikaday: _Pikaday, moment: _moment } = await import(
            './pikaday'
        );

        Pikaday = _Pikaday;
        moment = _moment;
    }

    $datePickers.each(function () {
        const localization = {
            previousMonth: w('Previous month'),
            nextMonth: w('Next month'),
            months: moment.localeData().months(),
            weekdays: moment.localeData().weekdays(),
            weekdaysShort: moment.localeData().weekdaysShort(),
        };

        const picker = new Pikaday({
            field: $(this)[0],
            firstDay: 1,
            format: 'DD-MM-YYYY',
            showDaysInNextAndPreviousMonths: true,
            i18n: localization,
        });

        $(this).on('keyup input', function (e) {
            var code = e.keyCode || e.which;

            if ($.inArray(code, [16, 17, 18, 37, 38, 39, 40]) > -1) {
                return false;
            }

            if (
                moment(
                    $(this).val().length === 10 && $(this).val(),
                    'DD-MM-YYYY'
                ).isValid()
            ) {
                picker.setMoment(moment($(this).val(), 'DD-MM-YYYY'));
            }
        });
    });

    $form.on('submit', async function (e) {
        e.preventDefault();

        const submitButton = $form.find('button')[0];
        setButtonLoading(submitButton, true);

        var data = new FormData();

        data.append('element', $element.data('jw-element-id'));
        data.append('values', JSON.stringify(formValues($form)));

        if ($form.find(':file').length !== 0) {
            $form.find('input[type=file]').each(function (index, element) {
                var files = element.files;

                for (var i = 0; i < files.length; i++) {
                    data.append(element.name, files[i]);
                }
            });
        }

        const response = await fetch(document.location.href, {
            method: 'POST',
            body: data,

            // Necessary to ensure cookies being sent. This setting has become
            // the default in the fetch() specification, but older browsers
            // don't send cookies by default.
            // https://github.com/whatwg/fetch/pull/585
            credentials: 'same-origin',
        });

        setButtonLoading(submitButton, false);

        if (response.ok) {
            // Replace the complete HTML; and reinitialize everything.
            const html = await response.text();
            $element.html(html);
            initializeContactForm($element);

            // trigger a custom event to initialize hCaptcha
            // (this is nasty but the current structure of the contact form --
            // where the entire HTML is replaced -- doesn't lend itself well to
            // our current hCaptcha setup)
            $element.find('form').trigger('jw-postsubmit');

            // Make sure we see the error message at the top of the form
            $element[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
            // Fix preview in debugger.
            void response.text();
            // Add (or replace) the error message to indicate something has gone wrong
            const errorHtml = `
                <div class="jw-element-form-error">
                    <strong>${w('Oops! Something went wrong.')}</strong>
                    <br>
                    ${errorMessageFromStatus(response.status)}
                </div>
            `;
            $element.find('jw-element-form-error').remove();
            $element.prepend(errorHtml);
        }
    });
}

domReady().then(() => {
    const $contactForms = $('.jw-contact-form');

    if ($contactForms.length === 0) {
        return;
    }

    $contactForms.each(function () {
        initializeContactForm($(this));
    });
});

export function parseNumber(value: string): number | null {
    if (value.trim().length === 0) {
        return null;
    }

    const number = Number(value.replace(',', '.'));
    if (isNaN(number) || !isFinite(number)) {
        return null;
    }

    return number;
}

export function parseNumberAsString(value: string): string | null {
    const number = parseNumber(value);
    if (number === null) {
        return null;
    }

    // Use NumberFormat to prevent scientific notation for large numbers. Not
    // using "fullwide" because it will format according to default locale.
    const numberFormat = new Intl.NumberFormat('en-US', {
        useGrouping: false,
    });
    return numberFormat.format(number);
}

import $ from 'jquery';

/**
 * Renders and unhides eaptcha on the given form's dedicated form group.
 *
 * @param {HTMLFormElement} form
 * @return {Promise<void>}
 */
export async function renderCaptchaOnForm(form) {
    // No token input means captcha is not enabled for the form.
    const captchaResponseField = form.querySelector('[name=captcha-response]');
    if (captchaResponseField === null) {
        return;
    }

    // Make sure that captcha is rendered only once for each form.
    if (captchaResponseField.dataset.captchaRendered === '1') {
        return;
    }
    captchaResponseField.dataset.captchaRendered = '1';

    // Check if we want to show a checkbox widget or not
    // We can just get the tokens
    if (captchaResponseField.dataset.checkbox === '1') {
        // Containing form group is always unhidden.
        toggleCaptchaVisibility(form, true);

        const captchaContainer = form.querySelector('.captcha-widget');
        const captcha = window.grecaptcha.enterprise || window.hcaptcha;
        captcha.render(captchaContainer, {
            sitekey: captchaResponseField.dataset.sitekey,
            action: captchaResponseField.dataset.action,
            callback: (token) => {
                captchaResponseField.value = token;
            },
        });
        return;
    }

    window.grecaptcha.enterprise.ready(async () => {
        const token = await window.grecaptcha.enterprise.execute(
            captchaResponseField.dataset.sitekey,
            { action: captchaResponseField.dataset.action }
        );
        captchaResponseField.value = token;
    });
}

/**
 * Toggles visibility of captcha form group.
 *
 * @param {HTMLFormElement} form
 * @param {boolean} visible
 * @return {void}
 */
export function toggleCaptchaVisibility(form, visible) {
    const captchaGroup = form.querySelector('.captcha-form-group');
    captchaGroup?.classList.toggle('hidden', !visible);
}

/**
 * @param {JQuery} $form
 * @return {void}
 */
export function renderCaptchaOnInteraction($form) {
    const form = $form[0];

    // Trigger directly when a form submission was done previously
    if (form.querySelector('.jw-element-form-is-error') !== null) {
        renderCaptchaOnForm(form);
    }

    $form.on('submit jw-postsubmit', () => renderCaptchaOnForm(form));

    $form.on('change keyup paste drop', 'input, textarea', () => {
        // Timeout required because pasted content will be set only _after_ this event.
        setTimeout(() => {
            if ($(this).val() === '') {
                return;
            }

            renderCaptchaOnForm(form);
        }, 0);
    });
}

import $ from 'jquery';

import { domReady } from 'common/dom';
import { open } from 'website-rendering/photoswipe';

domReady().then(() => {
    $('.jw-enlarge-image').on('click', function (e) {
        e.preventDefault();
        const img = this.querySelector('img');

        const item = {
            src: this.getAttribute('href'),
            w: parseInt(this.getAttribute('data-width'), 10),
            h: parseInt(this.getAttribute('data-height'), 10),
            aspect: parseFloat(this.getAttribute('data-aspect')),
            thumbAspect: parseFloat(this.getAttribute('data-thumb-aspect')),
        };

        const options = {};

        if (Math.abs(item.aspect - item.thumbAspect) <= 0.001) {
            item.msrc = img.currentSrc || img.src;

            options.getThumbBoundsFn = () => {
                const scrollY = window.pageYOffset;
                const { top, left, width } = img.getBoundingClientRect();

                return { x: left, y: top + scrollY, w: width };
            };
        } else {
            options.showHideOpacity = true;
        }

        open([item], options);
    });
});

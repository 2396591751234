import config from 'common/config';
import { domReady } from 'common/dom';

import fixFotoramaFullscreen from './fixFotoramaFullscreen';

domReady().then(() => {
    if (config.scripts.indexOf('fotorama') > -1) {
        import(/* webpackChunkName: "fotorama" */ 'lib/fotorama').then(() => {
            fixFotoramaFullscreen();
        });
    }

    if (config.scripts.indexOf('jquery.iframe-transport') > -1) {
        import(
            /* webpackChunkName: "jquery.iframe-transport" */ 'jquery.iframe-transport/jquery.iframe-transport'
        );
    }

    if (config.scripts.indexOf('audio') > -1) {
        import(/* webpackChunkName: "element-audio" */ './elements/audio');
    }

    if (config.scripts.indexOf('video/plyr') > -1) {
        import(/* webpackChunkName: "element-video-plyr" */ './elements/video');
    }

    if (config.scripts.indexOf('element/rss-renderer') > -1) {
        import(/* webpackChunkName: "element-rss" */ './elements/rss-renderer');
    }

    if (config.scripts.indexOf('website-rendering/slideshow') > -1) {
        import(
            /* webpackChunkName: "slideshow" */ 'website-rendering/slideshow'
        );
    }

    if (config.scripts.indexOf('website-rendering/webshop') > -1) {
        import(/* webpackChunkName: "webshop" */ 'website-rendering/webshop');
    }

    if (config.scripts.indexOf('webshop/stripe') > -1) {
        import(
            /* webpackChunkName: "webshop-stripe" */ 'website-rendering/webshop/stripe'
        );
    }

    if (config.scripts.indexOf('website-rendering/message-bar') > -1) {
        import(
            /* webpackChunkName: "message-bar" */ 'website-rendering/message-bar'
        );
    }
});

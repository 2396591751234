function resizeVimeo(container, wrapper) {
    let aspectRatio = 16 / 9;

    let videoWidth = parseInt(wrapper.dataset?.videoWidth, 10);
    let videoHeight = parseInt(wrapper.dataset?.videoHeight, 10);

    if (videoWidth && videoHeight) {
        aspectRatio = videoWidth / videoHeight;
    }

    let rect = container.getBoundingClientRect();
    let wrapperWidth = Math.ceil(
        Math.max(rect.width, rect.height * aspectRatio)
    );
    let wrapperHeight = Math.max(rect.height, rect.width / aspectRatio);

    wrapper.style.width = `${wrapperWidth + 1}px`; // pad 1px to prevent white edge
    wrapper.style.height = `${wrapperHeight}px`;
}

/**
 * Initialize video banner resizer so that videos properly cover the full banner, even when
 * the banner element dimensions are off (which happens on mobile).
 * (simulate background-size: cover)
 */
export function initVideoBannerResizer(container) {
    let wrapper = container.querySelector('div');
    let iframe = container.querySelector('iframe');

    let resizer = () => resizeVimeo(container, wrapper);
    iframe.addEventListener('load', resizer);
    window.addEventListener('resize', resizer);
    resizer();
}

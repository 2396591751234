import { w } from 'common/i18n/website-rendering';

interface ProductQuantityInputProps {
    onChange?: (quantity: number) => void;
    onRemove?: () => void;
}

/**
 * Enhances product quantity input by replacing it with a select if the quantity
 * is small.
 */
export class ProductQuantityInput {
    private readonly element: HTMLElement;
    private readonly props: ProductQuantityInputProps;
    private readonly input: HTMLInputElement;
    private readonly select: HTMLSelectElement;
    private readonly remove: HTMLElement;
    private readonly maxShownQuantity: number;
    private readonly productVariantId: number;
    private selectRendered = false;

    constructor(element: HTMLElement, props: ProductQuantityInputProps) {
        this.element = element;
        this.props = props;
        this.input = this.element.querySelector(
            '.product-quantity-input__input'
        )!;
        this.select = this.element.querySelector(
            '.product-quantity-input__select'
        )!;
        this.remove = this.element.querySelector(
            '.product-quantity-input__remove'
        )!;
        this.maxShownQuantity = Number(
            this.element.dataset.maxShownQuantity ?? 10
        );
        this.productVariantId = Number(this.element.dataset.productVariantId);

        this.initialize();
    }

    private initialize() {
        this.input.addEventListener('change', () => {
            this.props.onChange?.(this.getQuantity());
        });

        // Start out showing the select if the quantity is small
        if (this.getQuantity() <= this.maxShownQuantity) {
            this.select.classList.remove('hidden');
            this.input.classList.add('hidden');

            this.select.addEventListener('change', () => {
                // If the last option ("More") is selected, switch to numeric
                // input permanently
                if (Number(this.select.value) === this.maxShownQuantity + 1) {
                    this.select.classList.add('hidden');
                    this.input.classList.remove('hidden');
                    this.input.focus();

                    this.selectRendered = false;
                }

                // Synchronize input with select
                this.input.value = this.select.value;
                this.input.dispatchEvent(new CustomEvent('change'));
            });

            this.selectRendered = true;
        }

        this.remove?.addEventListener('click', () => {
            this.props.onRemove?.();
        });

        this.setMaxQuantity(Number(this.element.dataset.maxQuantity ?? 0));
    }

    /**
     * Updates the input's maximum and the select options if the select is
     * present.
     */
    setMaxQuantity = (maxQuantity: number): void => {
        maxQuantity = Number(maxQuantity);

        if (maxQuantity > 0) {
            this.input.max = String(maxQuantity);
        } else {
            this.input.removeAttribute('max');
        }

        // Disable input when there is nothing left
        this.input.disabled = maxQuantity === 0;
        this.select.disabled = maxQuantity === 0;

        // Set quantity to maximum if it was higher before
        if (maxQuantity > 0 && this.getQuantity() > maxQuantity) {
            this.input.value = String(maxQuantity);
            this.input.dispatchEvent(new CustomEvent('change'));
        }

        // Do not update the select if it is not in use
        if (!this.selectRendered) {
            return;
        }

        // Update select's options
        while (this.select.firstChild) {
            this.select.removeChild(this.select.firstChild);
        }

        const max =
            maxQuantity > 0
                ? Math.min(this.maxShownQuantity + 1, maxQuantity)
                : this.maxShownQuantity + 1;

        for (let i = 1; i <= max; i++) {
            const option = document.createElement('option');
            option.value = String(i);

            if (i === Number(this.input.value)) {
                option.selected = true;
            }

            if (i !== this.maxShownQuantity + 1) {
                option.textContent = String(i);
            } else {
                option.textContent = w('More');
            }

            this.select.appendChild(option);
        }
    };

    getQuantity = (): number => Number(this.input.value);
}

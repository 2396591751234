import { localeLanguage } from 'common/i18n/website-rendering';
import { isTouchDevice } from 'common/responsive';
import {
    isLanguageSupported,
    getMapboxStyleFor,
    ACCESS_TOKEN,
    createMarkerNodeFor,
} from './renderer';

function renderMap(container) {
    // Temporary keep track of how many times this map is loaded.
    try {
        if (window.ga) {
            window.ga(() => {
                if (typeof window.ga.getAll !== 'function') {
                    return;
                }

                // We only need to broadcast really to _one_ account. But as that is slightly more complicated,
                // let's push it to all accounts.
                for (let tracker of window.ga.getAll()) {
                    window.ga(
                        `${tracker.get('name')}.send`,
                        'event',
                        'element',
                        'lazy map load',
                        { nonInteraction: 1 }
                    );
                }
            });
        }
    } catch (e) {
        console.error(
            `Got error ${e}. But as this is logging code, let's continue.`
        );
    }

    Promise.all([
        import(/* webpackChunkName: "mapbox" */ 'mapbox-gl'),
        import(/* webpackChunkName: "mapbox" */ '@mapbox/mapbox-gl-language'),
        import(/* webpackChunkName: "mapbox" */ 'mapbox-gl/dist/mapbox-gl.css'),
    ]).then(([{ default: mapboxgl }, { default: MapboxLanguage }]) => {
        mapboxgl.accessToken = ACCESS_TOKEN;

        const {
            controls,
            theme,
            zoom,
            lng,
            lat,
            location,
            locationLng,
            locationLat,
        } = container.dataset;

        const map = new mapboxgl.Map({
            container: container.id,
            style: getMapboxStyleFor(theme),
            zoom,
            center: [lng, lat],
            dragPan: !isTouchDevice(),
        });

        if (isLanguageSupported(localeLanguage)) {
            map.addControl(
                new MapboxLanguage({
                    defaultLanguage: localeLanguage,
                })
            );
        }

        if (controls) {
            map.addControl(new mapboxgl.FullscreenControl());
            map.addControl(
                new mapboxgl.NavigationControl({ showCompass: false }),
                'bottom-right'
            );
        }

        map.on('load', function () {
            if (!locationLat || !locationLng) {
                return;
            }

            const popup = new mapboxgl.Popup({ offset: 25 }).setText(location);

            const markerElement = createMarkerNodeFor(theme);

            const marker = new mapboxgl.Marker(markerElement);
            marker.setLngLat([locationLng, locationLat]);
            marker.addTo(map);

            if (!isTouchDevice()) {
                marker.setPopup(popup);
            } else {
                markerElement.addEventListener('click', () => {
                    const mapLink = 'https://maps.apple.com';
                    window.location = `${mapLink}/?q=${location}&z=${zoom}`;
                });
            }
        });
    });
}

function initializeMaps() {
    const results = Array.from(document.getElementsByClassName('jw-map-div'));

    if (results.length === 0) {
        return;
    }

    // Lazy load maps as they become visible
    // Note, does not work on IE or Safari
    if (window.IntersectionObserver) {
        const onVisibilityChange = (entries, observer) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    return;
                }

                observer.unobserve(entry.target);
                renderMap(entry.target);
            });
        };

        const observer = new IntersectionObserver(onVisibilityChange, {
            threshold: 0.1,
        });

        results.forEach((element) => observer.observe(element));
    } else {
        results.forEach((element) => renderMap(element));
    }
}

if (document.readyState !== 'loading') {
    initializeMaps();
} else {
    document.addEventListener('DOMContentLoaded', initializeMaps);
}

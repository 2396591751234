import config from 'common/config';
import { postJSON, postUpload } from 'common/http';
import { w, wx } from 'common/i18n/website-rendering';
import { getVariantForCombination } from 'common/webshop/product';

import type {
    ProductVariantWithProperties,
    WebshopProductWithProperties,
} from 'app/features/webshop/types';

import { setButtonLoading } from 'website-rendering/helpers/loading';

import { ProductQuantityInput } from './ProductQuantityInput';
import { addedToWishlistModal } from './addedToCartModal';
import { type HasSelectedVariant } from './image-containers/HasSelectedVariant';
import { ProductImageContainer } from './image-containers/ProductImageContainer';
import { ProductImageGalleryContainer } from './image-containers/ProductImageGalleryContainer';
import { getCartProductQuantity } from './selectors';
import store from './store';
import { BackInStockInput } from './BackInStockInput';

interface ProductContainerProps {
    onAdd?: (
        selectedVariant: ProductVariantWithProperties,
        fieldValues: ProductFieldValue[],
        quantity: number
    ) => Promise<void>;
}

interface ProductFieldValue {
    id: number;
    input: string;
}

interface WebsiteUploadSuccessResponse {
    success: true;
    file: {
        publicId: string;
        url: string;
    };
}

interface WishListActionResponse {
    success: boolean;
    message?: string;
    totalItems?: number;
}

/**
 * Mounted on `.js-product-container` by `createProductContainers.js`. Rendered
 * by
 * `plugin-product/page-header.phtml`,
 * `product-gallery/_product-list.phtml`,
 * `plugin-product/plugin.phtml`, and
 * `webshop/wishlist/list.phtml`.
 *
 * ```html
 * <div data-webshop-product="{model}">
 *     <div class="js-product-container__number-container">
 *         <span class="js-product-container__number"></span>
 *     </div>
 *     <div>
 *         <div class="js-product-container__price"></div>
 *         <div class="js-product-container__free-shipping-motivator"></div>
 *     </div>
 *     <button class="js-product-container__button" />
 *     <select class="js-product-container__options">
 *         <option
 *             value="productVariantId"
 *             data-price-html="formatted price"
 *         >
 *         </option>
 *     </select>
 *
 *     <!-- Optional elements: -->
 *     <select class="js-product-container__properties">
 *         <option value="propertyId"></option>
 *     </select>
 *     <div class="product-quantity-input">
 *     </div>
 * </div>
 * ```
 */
export class ProductContainer {
    private readonly element: HTMLElement;
    private readonly props: ProductContainerProps;
    private readonly product: WebshopProductWithProperties;
    private readonly backInStockForm: BackInStockInput | undefined;
    private readonly variantSelect: HTMLSelectElement | null;
    private readonly propertySelects: HTMLSelectElement[];
    private readonly addButton: HTMLButtonElement;
    private readonly wishlistButton: HTMLButtonElement | null;
    private readonly prices: HTMLElement[];
    private readonly freeShippingMotivators: HTMLElement[];
    private readonly quantityInput: ProductQuantityInput | null = null;
    private readonly isDetailView: boolean;
    private readonly fieldInputs: HTMLInputElement[];
    private readonly productNumberContainers: HTMLElement[];
    private readonly productNumbers: HTMLElement[];
    private readonly buttonNotice: HTMLElement;
    private quantity = 1;
    private wishlistedVariantId: number | null;
    private imageContainer: HasSelectedVariant | null = null;

    constructor(element: HTMLElement, props: ProductContainerProps) {
        this.element = element;
        this.props = props;
        this.product = JSON.parse(this.element.dataset.webshopProduct!);
        this.isDetailView = Boolean(this.element.dataset.isDetailView);
        this.wishlistedVariantId = this.product?.wishlistedVariantId
            ? Number(this.product?.wishlistedVariantId)
            : null;
        this.variantSelect = this.element.querySelector(
            '.js-product-container__options'
        );
        this.addButton = this.element.querySelector(
            '.js-product-container__button'
        )!;
        this.wishlistButton = this.element.querySelector(
            '.js-product-container__wishlist-button'
        );
        this.prices = Array.from(
            this.element.querySelectorAll('.js-product-container__price')
        );
        this.freeShippingMotivators = Array.from(
            this.element.querySelectorAll(
                '.js-product-container__free-shipping-motivator'
            )
        );
        this.propertySelects = Array.from(
            this.element.querySelectorAll('.js-product-container__properties')
        );
        this.fieldInputs = Array.from(
            this.element.querySelectorAll('.js-product-container__field')
        );
        this.productNumberContainers = Array.from(
            this.element.querySelectorAll(
                '.js-product-container__number-container'
            )
        );
        this.productNumbers = Array.from(
            this.element.querySelectorAll('.js-product-container__number')
        );
        this.buttonNotice = this.element.querySelector(
            '.js-product-container__button-notice'
        )!;

        // Only initialize quantity input element when it exists (it will not be
        // rendered in certain situations)
        const quantityInputElement = element.querySelector<HTMLElement>(
            '.product-quantity-input'
        );
        if (quantityInputElement) {
            this.quantityInput = new ProductQuantityInput(
                quantityInputElement,
                {
                    onChange: (quantity) => (this.quantity = quantity),
                }
            );
        }

        let imageContainerElement =
            this.element.querySelector<HTMLElement>('.image-gallery');
        if (imageContainerElement) {
            this.imageContainer = new ProductImageGalleryContainer(
                imageContainerElement,
                { withPhotoSwipe: true }
            );
        }
        imageContainerElement =
            this.element.querySelector<HTMLElement>('.product-image');
        if (imageContainerElement) {
            this.imageContainer = new ProductImageContainer(
                imageContainerElement
            );
        }

        const backInStockElement = this.element.querySelector<HTMLElement>(
            '.js-back-in-stock-container'
        );
        if (backInStockElement) {
            this.backInStockForm = new BackInStockInput(backInStockElement);
        }

        this.initialize();

        this.element.classList.add('js-product-container--generated');
    }

    private initialize() {
        // Add product to cart
        this.addButton?.addEventListener('click', async () => {
            const selectedVariant = this.getSelectedVariant();
            if (
                selectedVariant?.stock === 0 &&
                selectedVariant.limited &&
                config.website.webshop.backInStockNotificationEnabled &&
                config.website.webshop.detailsPageAvailable &&
                !this.isDetailView
            ) {
                window.location.href = this.product.url;
                return;
            }

            if (this.shouldOrderOnDetailView()) {
                window.location.href = this.product.url;
                return;
            }

            try {
                setButtonLoading(this.addButton, true);
                const fieldValues = await this.processFieldValues();
                await this.props.onAdd?.(
                    this.getSelectedVariant()!, // Button will be disabled when null.
                    fieldValues,
                    this.quantity
                );
            } finally {
                setButtonLoading(this.addButton, false);
                this.update(); // Corrects button disabled state.
            }
        });

        this.wishlistButton?.addEventListener('click', async () => {
            // Update server state. Note that the order of postJSON and setting this.#wishlistedVariantId matters!
            const selectedVariant = this.getSelectedVariant();
            if (this.isWishlisted()) {
                if (this.wishlistedVariantId) {
                    const url = '_api/webshop/wishlist/remove';
                    const response = await postJSON<WishListActionResponse>(
                        url,
                        {
                            productVariantId: this.wishlistedVariantId,
                        }
                    );
                    if (response.success) {
                        this.showWishlistMenuItem();
                        this.updateWishlistCounter(response.totalItems);
                    }
                }
                this.wishlistedVariantId = null;
            } else {
                this.wishlistedVariantId = selectedVariant?.id ?? null;
                if (this.wishlistedVariantId) {
                    const url = '_api/webshop/wishlist/add';
                    const response = await postJSON<WishListActionResponse>(
                        url,
                        {
                            productVariantId: this.wishlistedVariantId,
                        }
                    );
                    if (response.success) {
                        this.showWishlistMenuItem();
                        this.updateWishlistCounter(response.totalItems);
                        addedToWishlistModal(this.product);
                    }
                }
            }

            this.updateWishlistIcon(selectedVariant);
        });

        this.element
            .querySelectorAll(
                '.js-product-container__options, .js-product-container__properties'
            )
            .forEach((element) => {
                // Necessary to get the chosen option, instead of the previous one
                // when navigating using keys. See http://stackoverflow.com/questions/9806257/select-change-event-not-fired-when-using-keyboard
                element.addEventListener('keydown', () =>
                    window.setTimeout(this.update, 0)
                );
                element.addEventListener('change', () =>
                    window.setTimeout(this.update, 0)
                );
            });

        // Clear field input errors on change.
        this.fieldInputs.forEach((fieldInput) =>
            fieldInput.addEventListener('change', () =>
                this.setFieldError(fieldInput, null)
            )
        );

        this.update();
    }

    private async processFieldValues(): Promise<ProductFieldValue[]> {
        return await Promise.all(
            Array.from(this.fieldInputs).map(
                async (fieldInput): Promise<ProductFieldValue> => {
                    let input = null;

                    try {
                        switch (fieldInput.dataset.fieldType) {
                            // TODO: Use PRODUCT_FIELD_TYPE_UPLOAD constant without
                            //  importing editor localization as a side effect.
                            case 'upload':
                                input =
                                    await this.processUploadField(fieldInput);
                                break;

                            default:
                                input = fieldInput.value;
                                break;
                        }
                    } catch (error: any) {
                        this.setFieldError(fieldInput, error.message);
                        throw error;
                    }

                    return {
                        id: Number(fieldInput.dataset.fieldId),
                        input,
                    };
                }
            )
        );
    }

    private async processUploadField(
        fieldInput: HTMLInputElement
    ): Promise<string> {
        if (fieldInput.files?.length !== 1) {
            throw new Error(w('Please select an image.'));
        }

        // Upload file and use upload ID as value.
        try {
            const response = await postUpload<WebsiteUploadSuccessResponse>(
                config.websiteRendering.routes['api/upload/product-field'],
                fieldInput.files[0]
            );
            return response.file.publicId;
        } catch (error: any) {
            let message = null;
            if (error.message?.success === false) {
                switch (error.message.reason) {
                    case 'maximumFileSize':
                        message = w('File is too large!');
                        break;

                    case 'invalidType':
                        message = w('File type is not supported.');
                        break;
                }
            }

            if (message === null) {
                message = w('An unknown error occurred.');
            }

            throw new Error(message);
        }
    }

    /**
     * TODO: Properly implement website-rendering JS validation/errors.
     */
    setFieldError(fieldInput: HTMLElement, message: string | null): void {
        const containerElement = fieldInput.closest<HTMLElement>(
            '.js-product-field-container'
        );
        const errorElement = containerElement?.querySelector<HTMLElement>(
            '.js-product-field-error'
        );
        if (!errorElement) {
            console.warn('Could not obtain form error element to update.');
            return;
        }

        errorElement.innerText = message || '';
        errorElement.classList.toggle('hidden', message === null);
        containerElement?.classList.toggle(
            'jw-element-form-is-error',
            message !== null
        );
    }

    /**
     * Updates elements of the product container based on the selected variant
     * or combination of properties.
     */
    update = (): void => {
        const selectedVariant = this.getSelectedVariant();

        if (selectedVariant && this.backInStockForm) {
            this.backInStockForm.update(selectedVariant);
        }

        if (!config.website.webshop.pricingVisible) {
            this.prices.forEach((price) => {
                price.classList.add('hidden');
            });
        } else if (this.variantSelect && selectedVariant) {
            // Update price
            const selectedVariantOption =
                this.variantSelect.querySelector<HTMLOptionElement>(
                    `option[value="${selectedVariant.id}"]`
                )!;

            const priceHtml = selectedVariantOption.dataset.priceHtml!;
            this.prices.forEach((price) => {
                price.innerHTML = priceHtml;
            });
        }

        // Check how many of the selected variant can still be ordered
        let quantityLeft = -1;
        if (selectedVariant?.limited) {
            const inCart = getCartProductQuantity(
                store.getState(),
                selectedVariant.id
            );
            quantityLeft = Math.max(selectedVariant.stock - inCart, 0);
        }

        // Update quantity input to maximum available
        this.quantityInput?.setMaxQuantity(quantityLeft);

        if (this.addButton) {
            if (!config.website.webshop.orderButtonVisible) {
                this.addButton.classList.add('hidden');
            } else {
                // Update button. Keep in sync with _order-button.phtml.
                const enabled =
                    config.website.webshop.enabled &&
                    config.website.allowed.webshop;
                const productEnabled = Boolean(selectedVariant);
                const outOfStock = productEnabled && quantityLeft === 0;
                const isPlaceholder = this.product.id === null;
                const allStockInCart =
                    outOfStock && productEnabled && selectedVariant!.stock > 0;

                let buttonLabel = wx('product button', 'Add to cart');
                if (!enabled) {
                    buttonLabel = wx('product button', 'Disabled');
                } else if (
                    outOfStock &&
                    (!config.website.webshop.backInStockNotificationEnabled ||
                        this.isDetailView ||
                        !config.website.webshop.detailsPageAvailable)
                ) {
                    buttonLabel = wx('product button', 'Sold out');
                } else if (
                    outOfStock &&
                    config.website.webshop.backInStockNotificationEnabled
                ) {
                    buttonLabel = wx(
                        'product button',
                        'Notify me when available'
                    );
                } else if (this.shouldOrderOnDetailView()) {
                    buttonLabel = wx('product button', 'See details');
                } else if (!productEnabled) {
                    if (this.propertySelects.length > 0) {
                        buttonLabel = wx('product button', 'Unavailable');
                    } else {
                        buttonLabel = wx('product button', 'Choose a variant');
                    }
                }

                // Product has stock but everything is in cart.
                let buttonTitle = buttonLabel;
                if (allStockInCart) {
                    buttonTitle = wx(
                        'product button',
                        'The last items are already in your cart.'
                    );
                }

                if (this.isDetailView) {
                    this.buttonNotice.innerText = allStockInCart
                        ? buttonTitle
                        : '';
                    this.buttonNotice.classList.toggle(
                        'hidden',
                        !allStockInCart
                    );
                }

                let disabled: boolean =
                    !enabled || !productEnabled || outOfStock || isPlaceholder;

                if (
                    outOfStock &&
                    enabled &&
                    productEnabled &&
                    config.website.webshop.backInStockNotificationEnabled &&
                    !this.isDetailView &&
                    config.website.webshop.detailsPageAvailable
                ) {
                    disabled = false;
                }

                this.addButton.disabled = disabled;
                this.addButton.innerHTML = `<span class="product__add-to-cart__label">${buttonLabel}</span>`;
                if (!this.shouldOrderOnDetailView()) {
                    this.addButton.innerHTML += `<span class="product__add-to-cart__icon website-rendering-icon-basket hidden"></span>`;
                }
                this.addButton.title = buttonTitle;
            }
        }

        // Render free shipping motivator if variant causes free shipping on its
        // own.
        this.freeShippingMotivators.forEach((freeShippingMotivator) => {
            freeShippingMotivator.innerText =
                selectedVariant?.freeShippingMotivator
                    ? w('FREE shipping')
                    : '';
        });

        // Update product number display.
        if (config.website.webshop.productNumbersEnabled) {
            this.productNumbers.forEach((productNumber) => {
                productNumber.innerText = selectedVariant?.productNumber || '';
            });
            this.productNumberContainers.forEach((productNumberContainer) => {
                productNumberContainer.classList.toggle(
                    'hidden',
                    !selectedVariant?.productNumber
                );
            });
        }

        if (selectedVariant) {
            this.imageContainer?.setSelectedVariant(selectedVariant);
        }

        // TODO: Update wishlisted variant if there was another variant already on this wishlist?
        this.updateWishlistIcon(selectedVariant);
    };

    /**
     * When true, the product is too complex to order from galleries/elements and must be ordered from the detail page.
     */
    shouldOrderOnDetailView(): boolean {
        return !this.isDetailView && this.product.fields.length > 0;
    }

    /**
     * Returns the selected product variant obtained from:
     * 1. Combination of selected properties (syncs direct selector as well). `null` when combination of properties does not exist (has been removed)
     * 2. Selected variant directly.
     * 3. The only variant of the product.
     */
    getSelectedVariant(): ProductVariantWithProperties | null {
        let selectedVariant;
        if (this.propertySelects.length > 0) {
            // Calculate and set variant based on selected property combination
            const values = Array.from(this.propertySelects, (input) => ({
                id: Number(input.value),
            }));
            selectedVariant = getVariantForCombination(
                this.product.variants,
                values
            );

            if (selectedVariant && this.variantSelect) {
                this.variantSelect.value = selectedVariant.id.toString();
            }

            return selectedVariant;
        }

        if (this.variantSelect) {
            const id = this.variantSelect.value;
            const filtered = this.product.variants.filter(
                (variant) => variant.id === Number(id)
            );

            if (filtered.length > 0) {
                return filtered[0];
            }
        }

        return this.product.variants[0];
    }

    updateWishlistIcon(
        selectedVariant: ProductVariantWithProperties | null
    ): void {
        if (this.wishlistButton) {
            this.wishlistButton.disabled =
                this.addButton.disabled || !selectedVariant;
        }

        // Matches the logic in `_wishlist-button.phtml`
        const wishlistIcon = this.element.querySelector(
            '.js-product-container__add-to-wishlist_icon'
        );
        wishlistIcon?.classList.toggle(
            'website-rendering-icon-heart',
            this.isWishlisted()
        );
        wishlistIcon?.classList.toggle(
            'website-rendering-icon-heart-empty',
            !this.isWishlisted()
        );
        wishlistIcon?.setAttribute(
            'title',
            this.isWishlisted()
                ? w('Remove from wishlist')
                : w('Add to wishlist')
        );
    }

    showWishlistMenuItem(): void {
        const wishlistMenuItem = document.querySelector(
            '.js-menu-wishlist-item'
        );
        wishlistMenuItem?.classList.remove('jw-menu-wishlist-item--hidden');
    }

    isWishlisted(): boolean {
        return this.wishlistedVariantId !== null;
    }

    getWishlistedVariant(): ProductVariantWithProperties | null {
        if (this.isWishlisted()) {
            const filtered = this.product.variants.filter(
                (variant) => variant.id === Number(this.wishlistedVariantId)
            );

            if (filtered.length > 0) {
                return filtered[0];
            }
        }
        return null;
    }

    updateWishlistCounter(counter = 0): void {
        const wishListMenuItemBadges = document.querySelectorAll<HTMLElement>(
            '.js-menu-wishlist-item .jw-icon-badge'
        );

        Array.from(wishListMenuItemBadges).forEach((wishListMenuItemBadge) => {
            wishListMenuItemBadge.classList.toggle('hidden', counter === 0);
            wishListMenuItemBadge.textContent = String(counter);
        });
    }
}

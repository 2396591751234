import $ from 'jquery';

import { domReady } from 'common/dom';
import { onElementLoad } from './frontend';

domReady().then(() => {
    const $albumRasters = $('.jw-element.jw-album-raster');
    if ($albumRasters.length < 1) {
        return;
    }

    $albumRasters.each(function () {
        onElementLoad($(this));
    });
});

import $ from 'jquery';

import config from 'common/config';
import { postJSON } from 'common/http';
import { w } from 'common/i18n/website-rendering';

import { renderCaptchaOnInteraction } from 'website-rendering/helpers/captcha';
import { setButtonLoading } from 'website-rendering/helpers/loading';

export function getVisibleNumberOfComments($element: JQuery): number {
    const $container = $element.find('.jw-comments-container');

    // only select direct children of .jw-comments in order to ignore
    // subcomments, as these should not affect the pagination.
    return $container.find('.jw-comments > .jw-comment:not(.is-not-visible)')
        .length;
}

export function getCommentsPerPage($element: JQuery): number {
    const $container = $element.find('.jw-comments-container');
    return parseInt($container.data('comments-per-page'), 10);
}

export function setCommentsPerPage($element: JQuery, perPage: number): void {
    const $container = $element.find('.jw-comments-container');
    $container.data('comments-per-page', perPage);
}

export function getTotalNumberOfComments($element: JQuery): number {
    const $container = $element.find('.jw-comments-container');
    return parseInt($container.data('number-of-comments'), 10);
}

export function initializeCommentForm($container: JQuery, $form: JQuery): void {
    // Initialize captcha element
    $form.find('[name=captcha]').prop('value', $container.data('container-id'));

    renderCaptchaOnInteraction($form);

    $form.on('submit', (e) => {
        e.preventDefault();

        const $comments = $container.find('.jw-comments-real');

        const $error = $form.find('.jw-comment-error');

        const submitButton = $form.find('button')[0];
        setButtonLoading(submitButton, true);

        const commentRequest = $.ajax({
            type: 'post',
            data: $form.serialize(),
        });

        commentRequest.done((data) => {
            $form
                .find('.jw-element-form-group')
                .removeClass('jw-element-form-is-error');

            const html = $(data);

            data = $.parseJSON(html.html());

            if (data.success) {
                if ($comments.find('.jw-comment').length === 0) {
                    $comments.empty();
                }

                $form.replaceWith(
                    `<div class="jw-element-form-success jw-comment-success">${data.successMessage}</div>`
                );

                if (data.gaJs) {
                    eval(data.gaJs);
                }

                $container.find('.jw-comments-dummy').hide();

                // Ignore `data.sortOrder` and always insert the new comment at the top, comments
                // at the bottom might not be seen on insertion.
                const $comment = $(data.comment);
                $comment.hide();

                if (data.isSubcomment) {
                    const $subcomments = $(
                        '.jw-subcomments[data-parent-id=' + data.parentId + ']'
                    );
                    $subcomments.show();
                    $subcomments.prepend($comment);
                } else {
                    $comments.prepend($comment);
                }

                $comment.fadeIn();

                const c = $('.jw-comments-container');
                c.data('number-of-comments', c.data('number-of-comments') + 1);
            } else {
                setButtonLoading(submitButton, false);

                const error = `
                    <strong>${w('Oops! Something went wrong.')}</strong>
                    <br>${w('Check the following fields and try again')}:
                    <ul>
                        ${Object.keys(data.messages)
                            .map((key) => `<li>${data.messages[key]}</li>`)
                            .join('\n')}
                    </ul>
                `;

                Object.keys(data.messages).forEach((key) => {
                    $form
                        .find(`[name=${key}]`)
                        .closest('.jw-element-form-group')
                        .addClass('jw-element-form-is-error');
                });

                $error.html(error);
                $error.show();
            }
        });
    });
}

/**
 * Give focus to the first form control in the form.
 * @param {jQuery} $form The form (wrapper) element
 */
function focusForm($form: JQuery): void {
    $form.find('input, select, textarea').first().focus();
}

/**
 * Show only the first batch of comments.
 */
export function reload($element: JQuery): void {
    const $container = $element.find('.jw-comments-container');
    const $all = $container.find('.jw-comment');
    const perPage = getCommentsPerPage($element);

    $all.slice(0, perPage).removeClass('is-not-visible');
    $all.slice(perPage).addClass('is-not-visible');

    $container.toggleClass(
        'is-more-comments',
        getTotalNumberOfComments($element) > perPage
    );
}

/**
 * Show the next page of comments. Will only make XJR request if no preloaded comments are
 * available.
 */
export function openNextPage($element: JQuery): void {
    const $container = $element.find('.jw-comments-container');
    const $preloadedComments = $container.find('.jw-comment.is-not-visible');

    // Show comments that are preloaded
    if ($preloadedComments.length > 0) {
        $preloadedComments
            .slice(0, getCommentsPerPage($element))
            .removeClass('is-not-visible')
            .slideDown();

        $container.toggleClass(
            'is-more-comments',
            $preloadedComments.length > getCommentsPerPage($element)
        );

        // Else fetch new comments from server
    } else {
        $container.addClass('is-loading-comments');
        $container.removeClass('is-more-comments');

        const commentsRequest = $.ajax({
            type: 'post',
            data: {
                element: $element.data('jw-element-id'),
                morePosts: true,
                beforeCommentId: $container
                    .find('.jw-comments > .jw-comment:last')
                    .data('id'),
            },
        });

        commentsRequest.done((data) => {
            const comments = $(data).children();

            $container.removeClass('is-loading-comments');

            // Append comments and replace pagination
            comments.hide();
            $container
                .find('.jw-comments')
                .children('.jw-comment,.jw-subcomments')
                .last()
                .after(comments);
            comments.slideDown();

            // There are more comments
            $container.toggleClass(
                'is-more-comments',
                getTotalNumberOfComments($element) >
                    getVisibleNumberOfComments($element)
            );
        });
    }
}

/**
 * React to a comment.
 */
export function reactComment($reactButton: JQuery): void {
    const parentId = $reactButton.data('id');

    const $element = $reactButton.closest('.jw-element');
    const $formWrapper = $element.find(
        `.jw-comment-form[data-parent-id=${parentId}]`
    );
    if ($formWrapper.length !== 0) {
        if ($formWrapper.find('form').length !== 0) {
            // A comment form already exists, give it focus
            focusForm($formWrapper);
            return;
        }

        // Wrapper exists but it contains no form, so replace it with a new form
        $formWrapper.remove();
    }

    const elementId = parseInt($element.attr('data-jw-element-id')!);

    $.ajax({
        type: 'post',
        data: 'action=form&element=' + elementId + '&parentId=' + parentId,
    })
        .done((response) => {
            const $formWrapper = $(response).find('div.jw-comment-form');
            const $form = $formWrapper.find('form');

            // Place form after parent comment
            $reactButton.closest('.jw-comment').after($formWrapper);

            // Give focus to the first form element
            focusForm($formWrapper);

            initializeCommentForm($element.find('.jw-comments-moddule'), $form);
        })
        .fail(() => {
            window.alert(
                w(
                    'Could not load the comment form. Reload the page and try again.'
                )
            );
        });
}

/**
 * Accepts a comment.
 */
export function approveComment($comment: JQuery): void {
    const commentId = parseInt($comment.data('id'));

    $comment.find('.jw-comment-accept').hide();

    postCommentAction(
        $comment.closest('.jw-element')[0],
        commentId,
        'approve'
    ).catch(() => {
        window.alert(
            w(
                'This comment could not be published. Reload the page and try again.'
            )
        );
    });
}

/**
 * Delete a comment.
 */
export function rejectComment($comment: JQuery): void {
    const commentId = parseInt($comment.data('id'));

    $comment.css('visibility', 'hidden');

    postCommentAction($comment.closest('.jw-element')[0], commentId, 'reject')
        .then(() => {
            // Remove the comment
            $comment.remove();

            // Remove related elements
            $('.jw-subcomments[data-parent-id=' + commentId + ']').remove();
            $('.jw-comment-form[data-parent-id=' + commentId + ']').remove();

            // Decrement comment count in container
            const $container = $comment.closest('.jw-comments-container');
            $container.data(
                'number-of-comments',
                $container.data('number-of-comments') - 1
            );
        })
        .catch(() => {
            $comment.find('.jw-comment-delete').hide();
            $comment.css('visibility', 'visible');
            window.alert(
                w(
                    'This comment could not be deleted. Reload the page and try again.'
                )
            );
        });
}

function postCommentAction(
    containerElement: HTMLElement,
    commentId: number,
    action: 'approve' | 'reject'
): Promise<void> {
    const containerId = Number(containerElement.dataset.jwElementId);
    // TODO: Do not reverse engineer news or comments for their separate controllers and just use one generic comments element.
    const isNews = containerElement.classList.contains('jw-newsPostComment');
    const segmentId = document.body.dataset.jouwwebSegmentId;

    let url = `${config.application.noSsl ? 'http' : 'https'}://${
        config.application.backendDomain
    }/v2/website/${config.website.id}`;
    if (isNews) {
        url += `/post/${segmentId}/comment/${commentId}/${action}`;
    } else {
        url += `/page/${segmentId}/comments/${containerId}/comment/${commentId}/${action}`;
    }

    return postJSON(
        url,
        {},
        {
            credentials: 'include',
        }
    );
}

import { w } from 'common/i18n/website-rendering';
import { allProductsInCart } from 'website-rendering/webshop/selectors';
import store from 'website-rendering/webshop/store';
import { addProductOptionToCart } from 'website-rendering/webshop/actions';
import addedToCartModal from 'website-rendering/webshop/addedToCartModal';
import { ProductContainer } from 'website-rendering/webshop/ProductContainer';

function addProduct(isCheckoutPage) {
    return async (productVariant, fields, quantity) => {
        if (productVariant.limited && productVariant.stock === 0) {
            window.alert(w('This product is sold out.'));
            return;
        }

        if (allProductsInCart(store.getState(), productVariant.id)) {
            window.alert(w('All remaining products are already in your cart.'));
            return;
        }

        const cartProduct = await store.dispatch(
            addProductOptionToCart(productVariant, fields, quantity)
        );

        if (isCheckoutPage) {
            window.location.reload();
        } else {
            addedToCartModal(cartProduct, quantity);
        }
    };
}

/**
 * @param {ParentNode} [scope]
 * @param {boolean} [isCheckoutPage]
 */
export default function createProductContainers(
    scope = document,
    isCheckoutPage = false
) {
    scope
        .querySelectorAll(
            '.js-product-container:not(.js-product-container--generated)'
        )
        .forEach((productElement) => {
            const productContainer = new ProductContainer(productElement, {
                onAdd: addProduct(isCheckoutPage),
            });

            // Update products upon mutation
            store.subscribe(productContainer.update);
        });
}

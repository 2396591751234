import watchFrame from './watchFrame';

function documentFrames() {
    return document.querySelectorAll('.jw-document-wrapper iframe');
}

function initFrames() {
    const frames = documentFrames();

    for (let i = 0; i < frames.length; i++) {
        watchFrame(frames[i]);
    }
}

if (document.readyState !== 'loading') {
    initFrames();
} else {
    document.addEventListener('DOMContentLoaded', initFrames);
}

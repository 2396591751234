import { combineReducers } from 'redux';

function inlineCartOpened(state = false, action) {
    switch (action.type) {
        case 'TOGGLE_INLINE_CART':
            return !state;

        default:
            return state;
    }
}

function inlineMobileCartOpened(state = false, action) {
    switch (action.type) {
        case 'TOGGLE_MOBILE_CART':
            return !state;

        default:
            return state;
    }
}

export default combineReducers({
    inlineCartOpened,
    inlineMobileCartOpened,
});

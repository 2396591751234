interface FrameServiceMethods<FrameService> {
    getFrameService: () => FrameService;
    setFrameService: (newFrameService: FrameService) => void;
    extendFrameService: (updatedProperties: Partial<FrameService>) => void;
}

export function makeFrameService<FrameService>(
    key: string,
    initialFrameService: FrameService,
    getWindow: () => Window
): FrameServiceMethods<FrameService> {
    function hasFrameService(): boolean {
        return key in getWindow();
    }

    function setFrameService(frameService: FrameService) {
        // @ts-expect-error [key] is not typed on the global Window interface,
        //  which is fine since it should only be accessed via these helpers
        getWindow()[key] = frameService;
    }

    function getFrameService(): FrameService {
        if (!hasFrameService()) {
            setFrameService(initialFrameService);
        }
        // @ts-expect-error [key] is not typed on the global Window interface,
        //  which is fine since it should only be accessed via these helpers
        return getWindow()[key];
    }

    function extendFrameService(updates: Partial<FrameService>) {
        setFrameService(Object.assign({}, getFrameService(), updates));
    }

    return {
        getFrameService,
        setFrameService,
        extendFrameService,
    };
}

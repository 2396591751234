import $ from 'jquery';
import { domReady } from 'common/dom';
import { locale } from 'common/i18n/website-rendering';
import scriptjs from 'scriptjs';

function getHref(el, url = document.location) {
    const template = el.getAttribute('data-href-template') ?? '';
    return template.replace(/\[url]/g, encodeURIComponent(url));
}

let facebookSdkPromise = null;
/**
 * Renders any unrendered Facebook like buttons on the page. Only loads in the
 * SDK when needed.
 *
 * @return {Promise<void>}
 */
export async function renderFacebookLikeButtons() {
    // Find all like buttons that are not rendered.
    const likeButtons = document.querySelectorAll(
        '.fb-like:not([fb-xfbml-state=rendered])'
    );
    if (likeButtons.length === 0) {
        return;
    }

    if (!facebookSdkPromise) {
        facebookSdkPromise = new Promise((resolve) => {
            const sdkLocale = locale.replace('-', '_');
            // Note: <div id="fb-root"> element is created by script itself when missing.
            scriptjs(
                `https://connect.facebook.net/${sdkLocale}/sdk.js#xfbml=1&version=v12.0`,
                () => resolve()
            );
        });
    }

    await facebookSdkPromise;

    likeButtons.forEach((likeButton) => {
        if (!window.FB) {
            throw new Error('Facebook SDK was not loaded correctly!');
        }

        window.FB.XFBML.parse(likeButton.parentNode);
    });
}

domReady().then(() => {
    // Load Pinterest code only if needed
    if ($('.share-button--pinterest').length > 0) {
        $.getScript('https://assets.pinterest.com/js/pinit.js');
    }

    $('.share-button')
        // Set `href`, so Ctrl-click will correctly open in new tab
        .attr('href', function () {
            const href = getHref(this);
            this.setAttribute('href', href);
        })
        // Open share-window on click
        .on('click', function (e) {
            if ($(this).hasClass('share-button--pinterest')) {
                /* global PinUtils */
                e.preventDefault();
                PinUtils.pinAny();
                return;
            }

            const href = getHref(this);
            if (href) {
                let openedWindow = window.open(
                    href,
                    '_blank',
                    'width=500,height=500,top=200,left=200'
                );

                // Only prevent default if window was succesfully opened
                // (window.open() returns null if window couldn't be opened)
                // This makes the browser fall back to default behavior when
                // dealing with things like ad/popup blockers.
                if (openedWindow) {
                    e.preventDefault();
                }
            }
        });

    renderFacebookLikeButtons();
});

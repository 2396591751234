import type { Options, Popup } from 'cookieconsent';

type AdditionalOptions = Pick<
    Options,
    'onStatusChange' | 'onRevokeChoice' | 'onPopupOpen'
>;

export function createPopup(
    options: Partial<AdditionalOptions> = {}
): Promise<Popup> {
    return new Promise((resolve, reject) => {
        // lazy-load cookieconsent module. It's not a very small library, and we
        // typically only need it for a single page view
        void import(
            /* webpackChunkName: "cookieconsent" */ 'cookieconsent'
        ).then(() => {
            cookieconsent?.initialise(
                {
                    ...options,
                    autoOpen: false, // we open our popups manually
                },
                resolve,
                reject
            );
        });
    });
}

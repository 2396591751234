export default class ProductGallerySorting {
    /** @var {HTMLSelectElement} */
    #select;

    /** @var {Function} */
    #onSort;

    constructor(element, { onSort }) {
        this.#select = element.querySelector('select');
        this.#onSort = onSort;

        this.#select.addEventListener('change', () => {
            this.#onSort(this.getCurrentSorting());
        });
    }

    getCurrentSorting() {
        return this.#select.value;
    }
}

import loadTranslator, { type Translator } from './loadTranslator';
import formatAddress, { type Address } from './formatAddress';
import formatNumber from './formatNumber';
import formatPercentage from './formatPercentage';
import fetchCountryNames, { type CountryNamesData } from './fetchCountryNames';
import {
    type CountrySubdivisionsData,
    fetchCountrySubdivisions,
    isCountrySubdivisionRequired,
} from './country-subdivision';
import createCurrencyMethods, {
    type CurrencyMethods,
} from './createCurrencyMethods';
import { configure as configureMoment } from './moment';
import { parseNumber, parseNumberAsString } from './parseNumber';

interface Language extends Translator, CurrencyMethods {
    locale: string;
    localeLanguage: string;
    localeRegion: string;
    timezone: string;
    tinymce: { language?: string };
    formatNumber(
        number: number,
        options?: Intl.NumberFormatOptions | null
    ): string;
    /**
     * Converts a formatted, possibly localized, number value to a decimal value
     * so it used for comparisons.
     */
    parseNumber(value: string): number | null;
    /**
     * Converts a formatted, possibly localized, number value to a decimal
     * string directly so it can be used in calls to the backend.
     */
    parseNumberAsString(value: string): string | null;
    formatPercentage(
        number: number,
        options?: Intl.NumberFormatOptions | null
    ): string;
    fetchCountryNames(): Promise<CountryNamesData>;
    isCountrySubdivisionRequired(
        countryCode: string | null | undefined
    ): boolean;
    fetchCountrySubdivisions(
        countryCode: string
    ): Promise<CountrySubdivisionsData>;
    formatAddress(address: Address): Promise<string>;
    /**
     * Placeholder function for strings that should not be translated yet due to
     * being region specific. Makes them easily searchable.
     */
    __(str: string): string;
}

export default function loadLanguage(
    domain: string,
    locale: string,
    timezone: string,
    currency: string
): Language {
    const translator = loadTranslator(domain, locale);

    // TinyMCE
    const tinymce =
        (window.JOUWWEB.localization &&
            window.JOUWWEB.localization[`tinymce:${locale}`]) ||
        {};

    // Split locale into language and region parts. Note that our pseudo locale
    // is inversed for some reason.
    const [localeLanguage, localeRegion] = locale.split('-');
    configureMoment({ locale, timezone });

    return {
        locale,
        localeLanguage,
        localeRegion,
        timezone,
        ...translator,
        tinymce,
        ...createCurrencyMethods(currency, locale),
        formatNumber: formatNumber.bind(null, locale),
        parseNumber,
        parseNumberAsString,
        formatPercentage: formatPercentage.bind(null, locale),
        fetchCountryNames: fetchCountryNames.bind(null, domain, locale),
        isCountrySubdivisionRequired,
        fetchCountrySubdivisions: fetchCountrySubdivisions.bind(
            null,
            domain,
            locale
        ),
        formatAddress: formatAddress.bind(null, domain, locale),
        __: (str: string): string => str,
    };
}

import $ from 'jquery';

import { domReady } from 'common/dom';
import { postFormData } from 'common/http';

import { initPagination } from 'website-rendering/helpers/pagination';
import scrollToElementTop from 'website-rendering/helpers/scollToElementTop';

async function getNewOverviewPage(page, $element) {
    $element.addClass('jw-element-is-loading');

    try {
        const data = await postFormData(window.location.href, {
            ep: {
                [$element.data('jw-element-id')]: {
                    page,
                },
            },
        });

        $element
            .find('.jw-element-news-content')
            .replaceWith($(data).find('.jw-element-news-content'));
    } finally {
        $element.removeClass('jw-element-is-loading');
    }
}

domReady().then(() => {
    const elements = document.getElementsByClassName('jw-news');

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const paginationElement = element.querySelector('.jw-pagination');

        if (paginationElement === null) {
            continue;
        }

        initPagination(paginationElement, {
            onPaginate: async (page) => {
                scrollToElementTop(element);
                await getNewOverviewPage(page - 1, $(element));
            },
        });
    }
});

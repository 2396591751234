const defaultOptions: RequestInit = {
    credentials: 'same-origin',
};

type FetchEventType = 'error' | 'send' | 'complete';

type FetchEvent = RequestInit & {
    type: FetchEventType;
    method: string;
    url: string;
    response?: Response | null;
    error?: Error;
};

interface FetchEventHandler {
    (event: FetchEvent): void;
}

const eventHandlers: Record<FetchEventType, FetchEventHandler[]> = {
    error: [],
    send: [],
    complete: [],
};

export class CustomFetchError<T> {
    message: T;
    responseStatus: number | null;

    constructor(message: T, response: Response | null) {
        this.message = message;
        this.responseStatus = response?.status || null;
    }
}

export function addFetchHandler(
    type: FetchEventType,
    handler: FetchEventHandler
): void {
    if (!(type in eventHandlers)) {
        throw new Error(`Unknown event type "${type}"`);
    }

    eventHandlers[type].push(handler);
}

function triggerEvent(
    type: FetchEventType,
    url: string,
    options: RequestInit,
    response: Response | null = null,
    error?: Error
) {
    eventHandlers[type].forEach((handler) =>
        handler({
            ...options,
            type,
            method: (options.method || 'GET').toUpperCase(),
            url,
            response,
            error,
        })
    );
}

function rejectIfBad<V>(response: Response, text: 'json'): Promise<V>;
function rejectIfBad(response: Response, text: 'text'): Promise<string>;
/**
 * Rejects with {@see CustomFetchError} when the request fails or the response
 * can't be parsed as the given data type.
 */
function rejectIfBad(response: Response, type: 'text' | 'json') {
    return new Promise((resolve, reject) => {
        return response[type]()
            .then((content) => {
                return response.ok
                    ? resolve(content)
                    : reject(new CustomFetchError(content, response));
            })
            .catch((reason: any) => reject(new CustomFetchError(reason, null)));
    });
}

export async function fetchBase(
    url: string,
    options: RequestInit = {}
): Promise<Response> {
    if (!options.headers) {
        options.headers = new Headers();
    } else if (!(options.headers instanceof Headers)) {
        options.headers = new Headers(options.headers);
    }

    // Add X-Requested-With header to be used as a rudimentary security check in
    // back-end. Skip for GET requests because they can't be trusted without
    // security tokens anyway.
    if (
        options.method &&
        options.method.toUpperCase() !== 'GET' &&
        !options.headers.get('X-Requested-With')
    ) {
        options.headers.append('X-Requested-With', 'fetch');
    }

    // Add X-Release-Tag header to trace errors back to a JS build.
    if (isSameOriginUrl(url) && !options.headers.has('X-Release-Tag')) {
        options.headers.append('X-Release-Tag', __RELEASE_TAG__);
    }

    triggerEvent('send', url, options);

    try {
        const response = await fetch(url, { ...defaultOptions, ...options });
        triggerEvent('complete', url, options, response);
        if (!response.ok) {
            triggerEvent('error', url, options, response);
        }

        return response;
    } catch (error: any) {
        if (error.name === 'AbortError') {
            throw error;
        }

        triggerEvent('error', url, options, null, error);
        triggerEvent('complete', url, options, null, error);

        throw error;
    }
}

export async function fetchJSON<V>(
    url: string,
    options: RequestInit = {
        headers: new Headers({
            Accept: 'application/json',
        }),
    }
): Promise<V> {
    const response = await fetchBase(url, options);
    return rejectIfBad<V>(response, 'json');
}

export async function fetchText(
    url: string,
    options: RequestInit = {}
): Promise<string> {
    const response = await fetchBase(url, options);
    return rejectIfBad(response, 'text');
}

export function postJSON<V>(
    url: string,
    data: unknown,
    options: RequestInit = {
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
    }
): Promise<V> {
    return fetchJSON<V>(url, {
        method: 'POST',
        body: JSON.stringify(data),
        ...options,
    });
}

export function postJSONFetchText(
    url: string,
    data: unknown,
    options: RequestInit = {
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
    }
): Promise<string> {
    return fetchText(url, {
        method: 'POST',
        body: JSON.stringify(data),
        ...options,
    });
}

export function postFormData(
    url: string,
    data: FormData | Record<string, unknown>,
    options: RequestInit = {}
): Promise<string> {
    if (!(data instanceof FormData)) {
        data = objectToFormData(data);
    }
    return fetchText(url, { method: 'POST', body: data, ...options });
}

/**
 * Posts data to the server similar to jQuery's `.post()` method.
 * @deprecated Use `postJSON()` where possible, as it is less verbose and less error-prone.
 */
export function postFormDataJSON<V>(
    url: string,
    data: Record<string, unknown>,
    options: RequestInit = {}
): Promise<V> {
    return fetchJSON<V>(url, {
        method: 'POST',
        body: objectToFormData(data),
        ...options,
    });
}

/**
 * Converts an object to a FormData object recursively.
 * TODO this could probably just use toFormData() from common/form?
 */
function objectToFormData(
    data: Record<string, unknown> | null,
    formData: FormData = new FormData(),
    prefix?: string
): FormData {
    if (data !== null) {
        Object.keys(data).forEach((key) => {
            const path = prefix ? `${prefix}[${key}]` : key;

            const property = data[key];
            if (property === null) {
                return;
            }

            if (typeof property !== 'object') {
                formData.append(path, property as string);
            } else {
                objectToFormData(
                    property as Record<string, unknown> | null,
                    formData,
                    path
                );
            }
        });
    }

    return formData;
}

export function postUpload<V>(
    url: string,
    file: File,
    formData = new FormData(),
    options: RequestInit = {}
): Promise<V> {
    // the filename is explicitly added to support uploading blobs with a
    // 'name' attribute
    formData.append('upload', file, file.name);

    return fetchJSON<V>(url, {
        method: 'POST',
        body: formData,
        ...options,
    });
}

export function patchJSON(
    url: string,
    data: unknown,
    options: RequestInit = {}
): Promise<Response> {
    return fetchBase(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
        ...options,
    });
}

/**
 * Returns whether the given URL points to the same origin as the current page.
 * This will always be `true` for relative URLs and `false` for malformed URLs.
 */
export function isSameOriginUrl(url: string): boolean {
    try {
        const urlObject = new URL(url, window.location.href);
        return urlObject.origin === window.location.origin;
    } catch (error) {
        return false;
    }
}

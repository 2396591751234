/**
 * Tests if given body has classes 'is-touch-device' or 'jw-is-touch-device'.
 * If no body is given and document.body exists, document.body is used.
 */
export function isTouchDevice(body: HTMLElement = document.body): boolean {
    if (!body) {
        return false;
    }

    return (
        body.classList.contains('is-touch-device') ||
        body.classList.contains('jw-is-touch-device')
    );
}

/**
 * Whether the current device is a mobile device. The specific meaning of
 * "mobile device" varies a bit throughout our application:
 *
 * - Landing: any touch device according to `Modernizr.touch` (see js/landing/legacyInit.js).
 * - Editor: any mobile/tablet device according to MobileDetect (see php/view/application/layout/layout.phtml), including iPadOS devices (see js/app/entry/initialize-backend.js).
 * - Website-rendering/editor-frame: only mobile devices, excluding tablet devices, according to MobileDetect (see php/view/page-rendering/block/segment.phtml).
 *
 * TODO: Make the meaning of "mobile device" more consistent.
 *
 * @param body - The `<body>` element of the window to check, defaults to current window.
 */
export function isMobileDevice(body: HTMLElement = document.body): boolean {
    if (!body) {
        return false;
    }

    return (
        body.classList.contains('is-mobile') ||
        body.classList.contains('jw-is-mobile')
    );
}

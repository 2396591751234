import { registerScrollEffect } from 'website-rendering/scroll-effects';

let hideStartY = null;

function updateEffect({ scrollY, scrollHeight, frameHeight, scrollStopped }) {
    const bottomBar = document.querySelector('.jw-bottom-bar__container');

    if (!bottomBar) {
        return;
    }

    bottomBar.classList.toggle(
        'jw-bottom-bar__container--is-scrolling',
        !scrollStopped
    );

    const bottomBarHeight = bottomBar.getBoundingClientRect().height;

    /*
     * Hide the bottom bar as the user scrolls down. Symmetrically, show the bar
     * again as the user scrolls up.
     */

    hideStartY = Math.min(hideStartY, scrollY);

    let translation = Math.min(scrollY - hideStartY, bottomBarHeight);

    if (scrollStopped) {
        // Once scrolling has stopped, either fully show or hide the bottom bar
        translation = translation > bottomBarHeight / 2 ? bottomBarHeight : 0;
        hideStartY = scrollY - translation;
    }

    // Force show at the bottom of the page
    translation = Math.min(translation, scrollHeight - frameHeight - scrollY);

    bottomBar.style.transform = `translate3d(0, ${translation}px, 0)`;
    bottomBar.style.webkitTransform = `translate3d(0, ${translation}px, 0)`;

    hideStartY = Math.max(hideStartY, scrollY - translation);
}

registerScrollEffect(updateEffect);

import { createFragment } from 'common/dom';
import $ from 'jquery';

import { postFormData } from 'common/http';
import { domReady } from 'common/dom';

function submitRating(vote, element) {
    const formData = new FormData();
    formData.append('vote', vote);
    formData.append('element', element.getAttribute('data-jw-element-id'));
    postFormData(window.location.href, formData).then((data) => {
        // Check that the element still exists on the page
        if (document.body.contains(element)) {
            const newElement = createFragment(data);
            element.parentNode.replaceChild(newElement, element);
        }
    });
}

domReady().then(() => {
    $(document)
        // Click on a star
        .on('click', '.jw-rating label.jw-element-rating__star', (e) => {
            const vote = $(e.currentTarget).prev().val();
            const $element = $(e.currentTarget).closest('.jw-element');
            submitRating(vote, $element[0]);
        })
        // Submit the form
        .on('submit', '.jw-rating form', (e) => {
            e.preventDefault();

            const vote = $(e.currentTarget)
                .find('input[name=vote]:checked')
                .val();
            const $element = $(e.currentTarget).closest('.jw-element');
            submitRating(vote, $element[0]);
        })
        .on(
            {
                mouseenter: (e) => {
                    const $element = $(e.currentTarget).closest('.jw-element');
                    $element.find('.jw-element-rating__text').hide();
                    $element
                        .find('.jw-element-rating__text--already-voted')
                        .show();
                },
                mouseleave: (e) => {
                    const $element = $(e.currentTarget).closest('.jw-element');
                    $element.find('.jw-element-rating__text').hide();
                    $element.find('.jw-element-rating__text--summary').show();
                },
            },
            '.jw-element-rating--voted .jw-element-rating__stars'
        );
});

import $ from 'jquery';
import './scroll-effects';

import { domReady } from 'common/dom';

domReady().then(() => {
    const config =
        window.JOUWWEB.templateConfig && window.JOUWWEB.templateConfig.header
            ? window.JOUWWEB.templateConfig.header
            : {};

    // Update header pusher when menu changes
    if (config.updatePusher) {
        const updatePusher = () => {
            const state = $('body').hasClass('jw-menu-is-desktop')
                ? 'desktop'
                : 'mobile';
            const headerSelector =
                state === 'desktop' ? config.selector : config.mobileSelector;
            const $header = $(headerSelector);

            let topHeight = 0;

            // If a page has a message-bar, add this height to it.
            const $messageBar = $('.message-bar');
            if ($messageBar.length > 0) {
                topHeight += $messageBar.outerHeight();
            }

            if ($header.is(':visible')) {
                topHeight += $header.outerHeight();
                config.updatePusher(topHeight, state);
            }
        };

        $(window).on('jw.menu-reload', () => {
            // Wait for menu reflow
            setTimeout(updatePusher, 10);
        });
    }
});

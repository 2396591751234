import $ from 'jquery';

import { domReady } from 'common/dom';
import { w } from 'common/i18n/website-rendering';
import { setButtonLoading } from 'website-rendering/helpers/loading';

function getCheckedOptionsFor($el) {
    const options = [];
    $el.find('input[name=option]:checked').each(function () {
        options.push(parseInt($(this).val(), 10));
    });
    return options;
}

domReady().then(() => {
    const $body = $('body');

    $body.on('click', '.jw-poll-show-results', function () {
        const elementDiv = $(this).closest('.jw-element');

        const resultsRequest = $.ajax({
            type: 'post',
            data: {
                do: 'show-results',
                element: elementDiv.data('jw-element-id'),
            },
        });

        resultsRequest.success((data) => {
            elementDiv.html(data);
        });

        return false;
    });

    $body.on('click', '.jw-poll-cast-vote', function () {
        const $el = $(this).closest('.jw-element');

        const submitButton = $el.find('.jw-poll-cast-vote')[0];
        setButtonLoading(submitButton, true);

        const castVoteRequest = $.ajax({
            type: 'post',
            data: {
                element: $el.data('jw-element-id'),
                options: getCheckedOptionsFor($el),
            },
        });

        castVoteRequest
            .success((data) => {
                $el.html(data);
            })
            .fail(() => {
                window.alert(
                    w('Something went wrong. Your vote was not submitted.')
                );
                setButtonLoading(submitButton, false);
            });

        return false;
    });
});

export interface Rect {
    x: number;
    y: number;
    width: number;
    height: number;
}

export function addRect(a: Rect, b: Rect): Rect {
    return {
        x: a.x + b.x,
        y: a.y + b.y,
        width: a.width + b.width,
        height: a.height + b.height,
    };
}

/**
 * Computes the bounding rect of a collection of rects, i.e. the rect that
 * exactly covers all given rects.
 */
export function computeBoundingRect(rects: Rect[]): Rect | null {
    if (rects.length === 0) {
        return null;
    }

    const { x1, y1, x2, y2 } = rects
        .map((rect) => {
            const { x, y, width, height } = rect;
            return { x1: x, y1: y, x2: x + width, y2: y + height };
        })
        .reduce((boundingRect, rect) => ({
            x1: Math.min(boundingRect.x1, rect.x1),
            y1: Math.min(boundingRect.y1, rect.y1),
            x2: Math.max(boundingRect.x2, rect.x2),
            y2: Math.max(boundingRect.y2, rect.y2),
        }));

    return {
        x: x1,
        y: y1,
        width: x2 - x1,
        height: y2 - y1,
    };
}

export function devicePixelRound(x: number): number {
    const devicePixelRatio = window.devicePixelRatio || 1;
    return Math.round(x * devicePixelRatio) / devicePixelRatio;
}

/**
 * Rounds the coordinates of the given rectangle, e.g. to align to device pixels.
 *
 * @param rect
 * @param roundCoord Rounding function, defaults to `Math.round`. See {@link devicePixelRound}.
 */
export function roundRect(rect: Rect, roundCoord = Math.round): Rect {
    const { x, y, width, height } = rect;
    const x1 = roundCoord(x);
    const x2 = roundCoord(x + width);
    const y1 = roundCoord(y);
    const y2 = roundCoord(y + height);
    return {
        x: x1,
        y: y1,
        width: x2 - x1,
        height: y2 - y1,
    };
}

export type BoxType = 'margin' | 'border' | 'padding' | 'content';

const sides = ['top', 'left', 'right', 'bottom'] as const;

/**
 * Get the bounding rect of a specific box (in the CSS box model) of the given
 * element, relative to the viewport. When `box` is 'border', the resulting
 * bounding rect is equivalent to the result of getBoundingClientRect().
 *
 * For definitions of the various box areas of the element, see:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Introduction_to_the_CSS_box_model
 */
export function getClientBoxRect(el: Element, box: BoxType = 'border'): Rect {
    const rect = el.getBoundingClientRect();
    const computedStyle = window.getComputedStyle(el);

    // Helper function to get a numeric property from the computed styles
    function cs(property: string): number {
        return parseInt(computedStyle.getPropertyValue(property), 10) || 0;
    }

    // Compute offset deltas for each side of the rect
    const [deltaTop, deltaLeft, deltaRight, deltaBottom] = sides.map((side) => {
        switch (box) {
            case 'margin':
                return cs(`margin-${side}`);
            case 'border':
                return 0;
            case 'padding':
                return -cs(`border-${side}-width`);
            case 'content':
                return -cs(`border-${side}-width`) - cs(`padding-${side}`);
        }
    });

    // Apply offset deltas to the bounding client rect
    const top = rect.top - deltaTop;
    const left = rect.left - deltaLeft;
    const right = rect.right + deltaRight;
    const bottom = rect.bottom + deltaBottom;

    // Compute the actual result rect
    return {
        x: left,
        y: top,
        width: right - left,
        height: bottom - top,
    };
}

/**
 * Similar to {@see getClientBoxRect}, except this function returns a rect that
 * is relative to the page instead of relative to the viewport.
 */
export function getPageBoxRect(el: Element, box?: BoxType): Rect {
    const { scrollX, scrollY } = window;
    const { x, y, width, height } = getClientBoxRect(el, box);

    return {
        x: x + scrollX,
        y: y + scrollY,
        width,
        height,
    };
}

/**
 * Similar to {@see getClientBoxRect}, except this function returns a rect that
 * is relative to the viewport of the topmost window, resolving the position of
 * any iframes in between.
 */
export function getTopWindowBoxRect(el: Element, box?: BoxType): Rect {
    const { x, y, width, height } = getClientBoxRect(el, box);

    let offsetX = 0;
    let offsetY = 0;

    let elWindow: Window | null = el.ownerDocument.defaultView;
    while (
        elWindow !== null &&
        elWindow.top !== null &&
        elWindow !== elWindow.top
    ) {
        const frameRect = elWindow.frameElement?.getBoundingClientRect();
        if (frameRect) {
            offsetX += frameRect.x;
            offsetY += frameRect.y;
        }
        elWindow = elWindow.parent;
    }

    return {
        x: x + offsetX,
        y: y + offsetY,
        width,
        height,
    };
}

export function isPointInRect(
    rect: Rect,
    pageX: number,
    pageY: number
): boolean {
    const { x, y, width, height } = rect;

    return pageX >= x && pageX < x + width && pageY >= y && pageY < y + height;
}
